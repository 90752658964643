export const ErrorForm = {
  Required: 'Trường này là bắt buộc',
  EmailInvalid: 'Email không đúng định dạng',
  MaximumUsernameLength: 'Mật khẩu từ 5 đến 30 ký tự',
  MaximumStringLength: name => `${name} từ 5 đến 30 ký tự`,
  MaximumPasswordLength: 'Mật khẩu từ 8 đến 20 ký tự',
  PasswordInvalid: 'Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt',
  PasswordNotSame: 'Mật khẩu xác nhận phải giống với mật khẩu mới',
  MinMoney: amount => `Số tiền phải lớn hơn hoặc bằng ${amount}`,
  MaxMoney: amount => `Số tiền phải nhỏ hơn hoặc bằng ${amount}`,
  MaxLength: length => `Bạn được nhập tối đa ${length} ký tự`,
  UrlInValid: 'Url không đúng định dạng',
  PhoneInvalid: 'Số điện thoại không đúng định dạng',
  MaximumPhoneLength: 'Số điện thoại bao gồm 10 chữ số',
};

export const ErrorApi = {
  WRONG_USERNAME_OR_PASSWORD: 'Tên đăng nhập hoặc mật khẩu sai',
  THE_USERNAME_OR_EMAIL_EXISTED: 'Tài khoản này đã tồn tại',
  CAMPAIGN_GROUP_EXISTS: 'Tên nhóm chiến dịch đã tồn tại',
  CAMPAIGN_GROUP_IS_USING_CAN_NOT_DELETE: 'Không thể xóa nhóm chiến dịch đang sử dụng.',
  THE_WEBSITE_CATEGORY_IS_INVALID_OBJECT_ID: 'Id đối tượng danh mục website không hợp lệ',
  THE_WEBSITE_ID_IS_REQUIRE_AND_IS_VALID_OBJECT_ID: 'Id website là bắt buộc và là id đối tượng hợp lệ',
  THE_AD_UNIT_ID_IS_REQUIRE_AND_IS_VALID_OBJECT_ID: 'Id đơn vị quảng cáo là bắt buộc và là id đối tượng hợp lệ',
  THE_DIRECT_LINK_ID_IS_REQUIRE_AND_IS_VALID_OBJECT_ID: 'Id liên kết trực tiếp là bắt buộc và là id đối tượng hợp lệ',
  THE_STATUS_IS_INVALID: 'Trạng thái không hợp lệ',
  THE_WEBSITE_AD_UNIT_IS_INVALID: 'Đơn vị quảng cáo website không hợp lệ',
  THE_WEBSITE_AD_UNIT_IS_REQUIRE: 'Đơn vị quảng cáo website là bắt buộc',
  THE_WEBSITE_REMOVE_CAMPAIGN_WITH_IS_INVALID: 'Website xóa chiến dịch với là không hợp lệ',
  THE_WEBSITE_CATEGORY_IS_REQUIRE: 'Danh mục website là bắt buộc',
  THE_WEBSITE_URL_IS_INVALID: 'Url website không hợp lệ',
  THE_WEBSITE_URL_IS_REQUIRE: 'Url website là bắt buộc',
  THE_WEBSITE_NAME_IS_REQUIRE: 'Tên website là bắt buộc',
  THE_GROUP_IS_REQUIRED: 'Nhóm là bắt buộc',
  THE_COUNTRY_CODE_IS_REQUIRE: 'Mã quốc gia là bắt buộc',
  THE_VALUE_COUNTRY_CODE_NOT_EXISTS: 'Giá trị mã quốc gia không tồn tại',
  THE_VALUE_CONTACT_CHANNEL_NOT_EXISTS: 'Giá trị kênh liên hệ không tồn tại',
  THE_CONTACT_CHANNEL_IS_REQUIRE: 'Kênh liên hệ là bắt buộc',
  THE_CONTACT_USERNAME_IS_REQUIRE: 'Tên người dùng liên hệ là bắt buộc',
  THE_CATEGORY_IS_REQUIRE: 'Danh mục là bắt buộc',
  RATIO_IS_REQUIRE: 'Tỉ lệ là bắt buộc',
  AMOUNT_IS_REQUIRE: 'Số tiền là bắt buộc',
  THE_ADVERTISER_ID_IS_REQUIRE: 'Id quảng cáo là bắt buộc',
  THE_PUBLISHER_ID_IS_REQUIRE: 'Id nhà xuất bản là bắt buộc',
  CHANNEL_IS_REQUIRE: 'Kênh là bắt buộc',
  BANK_CODE_IS_REQUIRE: 'Mã ngân hàng là bắt buộc',
  THE_GENDER_IS_REQUIRE: 'Giới tính là bắt buộc',
  THE_GENDER_INVALID: 'Giới tính không hợp lệ',
  UPLOAD_IMAGE_ONLY: 'Chỉ được tải lên ảnh',
  THE_BIRTHDAY_IS_REQUIRE: 'Ngày sinh là bắt buộc',
  THE_TOKEN_IS_REQUIRE: 'Token là bắt buộc',
  THE_PHONE_IS_REQUIRE: 'Số điện thoại là bắt buộc',
  PHONE_NUMBER_INVALID_FORMAT: 'Định dạng số điện thoại không hợp lệ',
  THE_WALLET_CODE_IS_REQUIRE: 'Mã ví là bắt buộc',
  THE_WALLET_NETWORK_IS_REQUIRE: 'Mạng ví là bắt buộc',
  THE_BANK_NAME_IS_REQUIRE: 'Tên ngân hàng là bắt buộc',
  THE_BANK_ACCOUNT_NAME_IS_REQUIRE: 'Tên tài khoản ngân hàng là bắt buộc',
  THE_BANK_ACCOUNT_NUMBER_IS_REQUIRE: 'Số tài khoản ngân hàng là bắt buộc',
  THE_WALLET_NETWORK_IS_IN_VALID: 'Mạng ví không hợp lệ',
  THE_FULLNAME_IS_REQUIRE: 'Họ và tên là bắt buộc',
  FULLNAME_INVALID_FORMAT: 'Định dạng họ và tên không hợp lệ',
  THE_USER_ID_IS_REQUIRE: 'Id người dùng là bắt buộc',
  USERNAME_MIN_4_MAX_20: 'Tên đăng nhập từ 4 đến 20 ký tự',
  THE_USERNAME_IS_REQUIRE: 'Tên đăng nhập là bắt buộc',
  USERNAME_INVALID_FORMAT: 'Định dạng tên đăng nhập không hợp lệ',
  MISSING_FIELD_REQUIRE: 'Thiếu trường bắt buộc',
  USER_NOT_FOUND: 'Không tìm thấy người dùng',
  THE_USERNAME_OR_EMAIL_EXISTED: 'Tên đăng nhập hoặc email đã tồn tại',
  WRONG_USERNAME_OR_PASSWORD: 'Sai tên đăng nhập hoặc mật khẩu',
  THE_ROUND_ID_IS_REQUIRE: 'Id vòng đấu là bắt buộc',
  THE_PUBLISHERS_IS_REQUIRE: 'Nhà xuất bản là bắt buộc',
  THE_AWAY_ID_IS_REQUIRE: 'Id đội khách là bắt buộc',
  THE_HOME_ID_IS_REQUIRE: 'Id của đội nhà là bắt buộc',
  THE_HOME_NAME_IS_REQUIRE: 'Tên đội nhà là bắt buộc',
  THE_AWAY_NAME_IS_REQUIRE: 'Tên đội khách là bắt buộc',
  THE_PHONE_EXISTED: 'Số điện thoại đã tồn tại',
  THE_LEAGUE_IS_REQUIRE: 'Giải đấu là bắt buộc',
  THE_TIME_IS_REQUIRE: 'Thời gian là bắt buộc và sau giờ hiện tại',
  THE_LEAGUE_NAME_IS_REQUIRE: 'Tên giải đấu là bắt buộc',
  THE_MATCH_ID_IS_REQUIRE: 'Id trận đấu là bắt buộc',
  THE_TIME_IS_AFTER_NOW: 'Thời gian là sau giờ hiện tại',
  CANNOT_DELETE_LIVE_OR_FINISH_MATCH: 'Không thể xoá trận đấu đang diễn ra hoặc đã kết thúc',
  MATCH_NOT_EXIST: 'Trận đấu không tồn tại',
  CANNOT_UPDATE_LIVE_MATCH: 'Không thể cập nhật trận đấu đang diễn ra',
  GOALS_IS_NUMBER: 'Bàn thắng là số',
  TITLE_IS_EXIST: 'Tiêu đề đã tồn tại',
  POST_TYPE_IS_EXIST: 'Loại bài đăng là bắt buộc',
  CATEGORY_IS_EXIST: 'Danh mục là bắt buộc',
  THUMBNAIL_IS_REQUIRE: 'Ảnh thu nhỏ là bắt buộc',
  VIDEO_HIGHLIGHT_IS_REQUIRE: 'Video highlight là bắt buộc',
  THE_POST_ID_IS_REQUIRE: 'ID bài đăng là bắt buộc',
  THE_TEAM_ID_IS_REQUIRE: 'ID đội là bắt buộc',
  THE_SEASON_ID_IS_REQUIRE: 'ID mùa giải là bắt buộc',
  THE_GROUP_ID_IS_REQUIRE: 'ID nhóm là bắt buộc',
  THE_SEASON_YEAR_IS_4_DIGIT: 'Năm mùa giải là 4 chữ số',
  THE_SEASON_NUMBER_TEAM_IS_2_DIGIT: 'Số đội trong mùa giải là 2 chữ số',
  THE_NUMBER_TEAM_FOR_KNOCKOUT_IS_2_DIGIT: 'Số đội cho vòng knockout là 2 chữ số và nhỏ hơn số đội và là 8, 16 hoặc 32',
  THE_POINTS_FOR_WIN_IS_1_DIGIT: 'Điểm dành cho chiến thắng là 1 chữ số và từ 1 đến 3',
  THE_POINTS_FOR_DRAW_IS_1_DIGIT: 'Điểm dành cho hòa là 1 chữ số và từ 1 đến 2',
  THE_SEASON_NUMBER_GROUP_IS_2_DIGIT: 'Số lượng nhóm trong mùa giải là 2 chữ số',
  THE_PAGE_ID_MUST_VALID_OBJECT_ID: 'ID trang phải là object ID hợp lệ',
  THE_TEAM_ID_MUST_VALID_OBJECT_ID: 'ID đội phải là object ID hợp lệ',
  THE_RANK_ID_MUST_VALID_OBJECT_ID: 'ID hạng lệ phải là object ID hợp lệ',
  THE_CONTENT_ID_MUST_VALID_OBJECT_ID: 'ID nội dung phải là object ID hợp lệ',
  THE_POST_ID_MUST_VALID_OBJECT_ID: 'ID bài đăng phải là object ID hợp lệ',
  THE_PAGE_ID_IS_REQUIRE: 'ID trang là bắt buộc',
  THE_PAGE_ID_IS_INVALID: 'ID trang không hợp lệ',
  THE_LAYOUT_IS_INVALID: 'Layout không hợp lệ',
  THE_LAYOUT_IS_REQUIRE: 'Layout là bắt buộc',
  THE_CONTENT_IS_REQUIRE: 'Nội dung là bắt buộc',
  THE_CONTENT_ID_IS_REQUIRE: 'ID nội dung là bắt buộc',
  THE_THUMBNAIL_IS_REQUIRE: 'Ảnh thu nhỏ là bắt buộc',
  THE_RANK_ID_IS_REQUIRE: 'ID hạng lệ là bắt buộc',
  THE_SEASON_ID_MUST_VALID_OBJECT_ID: 'ID mùa giải phải là object ID hợp lệ',
  THE_LEAGUE_ID_MUST_VALID_OBJECT_ID: 'ID giải đấu phải là object ID hợp lệ',
  THE_GROUP_ID_MUST_VALID_OBJECT_ID: 'ID nhóm phải là object ID hợp lệ',
  THE_CATEGORY_ID_IS_REQUIRE: 'ID danh mục là bắt buộc',
  THE_BODY_IS_REQUIRE: 'Nội dung là bắt buộc',
  THE_TITLE_IS_REQUIRE: 'Tiêu đề là bắt buộc',
  THE_DESCRIPTION_IS_REQUIRE: 'Mô tả là bắt buộc',
  THE_NUMBER_TEAM_FOR_KNOCKOUT_IS_REQUIRE: 'Số đội cho vòng knockout là bắt buộc',
  THE_NAME_IS_REQUIRE: 'Tên là bắt buộc',
  THE_YEAR_IS_REQUIRE: 'Năm là bắt buộc',
  THE_POINTS_FOR_WIN_IS_REQUIRE: 'Điểm cho chiến thắng là bắt buộc',
  THE_POINTS_FOR_DRAW_IS_REQUIRE: 'Điểm cho hòa là bắt buộc',
  THE_SEASON_NUMBER_TEAM_IS_REQUIRE: 'Số mùa của đội là bắt buộc',
  THE_SEASON_NUMBER_GROUP_IS_REQUIRE: 'Số mùa của nhóm là bắt buộc',
  THE_TYPE_IS_REQUIRE: 'Loại là bắt buộc',
  THE_LEAGUE_ID_IS_REQUIRE: 'ID của giải đấu là bắt buộc',
  THE_TEAM_IDS_IS_REQUIRE: 'ID của đội bóng là bắt buộc',
  THE_RANK_IDS_IS_REQUIRE: 'ID của xếp hạng là bắt buộc',
  LOGO_IS_REQUIRE: 'Logo là bắt buộc',
  THE_VIDEO_URL_REQUIRE: 'URL video là bắt buộc',
  POST_TYPE_IS_REQUIRE: 'Loại bài viết là bắt buộc',
  POST_TYPE_IS_INVALID: 'Loại bài viết không hợp lệ',
  INVALID_TOKEN: 'Token không hợp lệ',
  SEASON_ON_GOING_OR_END: 'Không thể cập nhật mùa giải đang diễn ra hoặc đã kết thúc',
  LEAGUE_ONLY_UPCOMING_SEASON: 'Chỉ có một mùa giải sắp tới cho giải đấu',
  THE_STATUS_SEASON_NOT_MATCH: 'Trạng thái mùa giải không phù hợp',
  THE_SEASON_TYPE_NOT_MATCH: 'Loại mùa giải không phù hợp',
  THE_CATEGORY_ID_MUST_VALID_OBJECT_ID: 'ID danh mục phải là đối tượng hợp lệ',
  THE_EMAIL_INVALID_FORMAT: 'Định dạng email không hợp lệ',
  THE_PASSWORD_IS_INVALID_FORMAT: 'Định dạng mật khẩu không hợp lệ',
  THE_PASSWORD_CONFIRM_DO_NOT_MATCH: 'Mật khẩu xác nhận không khớp',
  THE_PASSWORD_IS_REQUIRE: 'Mật khẩu là bắt buộc',
  THE_OTP_IS_REQUIRE: 'OTP là bắt buộc',
  THE_NEW_PASSWORD_IS_REQUIRE: 'Mật khẩu mới là bắt buộc',
  THE_PASSWORD_CONFIRM_IS_REQUIRE: 'Xác nhận mật khẩu là bắt buộc',
  THE_EMAIL_IS_REQUIRE: 'Email là bắt buộc',
  SEND_EMAIL_OTP_CODE_PLS_CHECK_EMAIL: 'Gửi mã OTP qua email, vui lòng kiểm tra email',
  SYSTEM_UNSTABLE: 'Hệ thống không ổn định, vui lòng thử lại',
  WRONG_PASSWORD: 'Sai mật khẩu',
  WRONG_OTP: 'Sai OTP',
  CHANGE_PASSWORD_SUCCESS: 'Thay đổi mật khẩu thành công',
  GOAL_IS_WRONG: 'Mục tiêu không đúng',
  STATUS_MATCH_IS_REQUIRE: 'Trạng thái phải khớp',
  STATUS_MATCH_IS_ONE_OF: 'Trạng thái phải là một trong những',
  THE_WEBSITEADS_IS_REQUIRE: 'Quảng cáo trên trang web là bắt buộc',
  ALL_LIVE_MATCHES_UPDATE: 'Tất cả trận đấu đang diễn ra đã được cập nhật',
  UPDATE_LINK_LIVE: 'Cập nhật liên kết trực tiếp',
  VIDEO_DEFAULT_UPDATE_LINK: 'Cập nhật liên kết mặc định của video',
  VIDEO_DEFAULT_UPDATE_THUMBNAIL: 'Cập nhật hình thu nhỏ mặc định của video',
  TRACKING_CPM_SUCCESSFULLY: 'Theo dõi CPM thành công',
  DAILY_BUDGET_LESS_THAN_OR_EQUAL_DAILY_BUDGET_CAMPAIGN: 'Ngân sách hàng ngày nhỏ hơn hoặc bằng ngân sách hàng ngày của chiến dịch',
  PUBLISHER_NOT_FOUND: 'Nhà xuất bản không tìm thấy',
  THE_VALUE_BANNER_SIZE_NOT_EXISTS: 'Kích thước biểu ngữ không tồn tại',
  CAN_NOT_CLICK_AGAIGN: 'Không thể nhấp chuột lại',
  DOMAIN_FAILED: 'Miền không thành công',
  ADVERTISER_NOT_FOUND: 'Nhà quảng cáo không tìm thấy',
  WEBSITE_NOT_FOUND: 'Trang web không tìm thấy',
  TRACKING_CLICK_FAILED: 'Theo dõi nhấp chuột thất bại',
  TRACKING_CLICK_SUCCESSFULLY: 'Theo dõi nhấp chuột thành công',
  OS_NOT_MATCH: 'Hệ điều hành không khớp',
  DEVICE_NOT_MATCH: 'Thiết bị không khớp',
  VERSION_NOT_MATCH: 'Phiên bản không khớp',
  AD_UNIT_NOT_FOUND: 'Đơn vị quảng cáo không tìm thấy',
  CITY_NOT_MATCH: 'Thành phố không khớp',
  COUNTRY_NOT_MATCH: 'Quốc gia không khớp',
  CAMPAIGN_EXCEED_LIMIT: 'Chiến dịch vượt quá giới hạn',
  CPM_LESS_THAN_OR_EQUAL_DAILY_BUDGET: 'CPM nhỏ hơn hoặc bằng ngân sách hàng ngày',
  CPC_LESS_THAN_OR_EQUAL_DAILY_BUDGET: 'CPC nhỏ hơn hoặc bằng ngân sách hàng ngày',
  CAMPAIGN_ACTIVE_CAN_NOT_EDIT: 'Không thể chỉnh sửa chiến dịch đang hoạt động',
  ADVERTISER_NOT_ENOUGH_BALANCE: 'Nhà quảng cáo không đủ số dư',
  CAMPAIGN_NOT_ACTIVE: 'Chiến dịch không hoạt động',
  GET_LIST_CAMPAIGNS_SUCCESSFULLY: 'Nhận danh sách chiến dịch thành công',
  CAMPAIGN_WEBSITEADS_NOT_FOUND: 'Không tìm thấy chiến dịch quảng cáo trên trang web',
  ASSIGN_CAMPAIGN_FOR_WEBSITEADS_SUCCESSFULLY: 'Gán chiến dịch cho quảng cáo trên trang web thành công',
  DATA_SCHEDULE_DATE_INVALID: 'Ngày lên lịch dữ liệu không hợp lệ',
  THE_VALUE_STATUS_NOT_EXISTS: 'Trạng thái không tồn tại',
  THE_VALUE_STATUS_IS_REQUIRED: 'Trạng thái là bắt buộc',
  UPDATE_STATUS_CAMPAIGN_SUCCESSFULLY: 'Cập nhật trạng thái chiến dịch thành công',
  ADVERTISER_NOT_ENOUGH_BALANCE: 'Nhà quảng cáo không đủ số dư',
  NOT_PERMISSION_UPDATE_CAMPAIGN_GROUP: 'Không có quyền cập nhật nhóm chiến dịch',
  THE_VALUE_CPC_INVALID: 'Giá CPC không hợp lệ',
  THE_CPC_REQUIRE: 'Giá CPC là bắt buộc',
  THE_VALUE_COMPETITIVENESS_NOT_EXISTS: 'Giá trị đối thủ không tồn tại',
  THE_VALUE_OS_NAME_NOT_EXISTS: 'Tên hệ điều hành không tồn tại',
  CAMPAIGN_GROUP_NOT_FOUND: 'Nhóm chiến dịch không tìm thấy',
  CAMPAIGN_GROUP_EXISTS: 'Nhóm chiến dịch đã tồn tại',
  CAMPAIGN_EXISTS: 'Chiến dịch đã tồn tại',
  DAILY_BUDGET_INVALID: 'Ngân sách hàng ngày không hợp lệ',
  MINIMUMCTR_INVALID: 'Tỷ lệ nhấp chuột tối thiểu không hợp lệ',
  MAX_CONVERSION_COST_INVALID: 'Chi phí chuyển đổi tối đa không hợp lệ',
  CREATE_CAMPAIGN_GROUP_SUCCESSFULLY: 'Tạo nhóm chiến dịch thành công',
  CREATE_CAMPAIGN_GROUP_FAILED: 'Tạo nhóm chiến dịch thất bại',
  GROUP_NAME_INVALID_FORMAT: 'Định dạng tên nhóm không hợp lệ',
  CAMPAIGN_NAME_INVALID_FORMAT: 'Định dạng tên chiến dịch không hợp lệ',
  GROUP_ID_IS_REQUIRE: 'ID nhóm là bắt buộc',
  GROUP_NAME_IS_REQUIRE: 'Tên nhóm là bắt buộc',
  CAMPAIGN_ID_IS_REQUIRE: 'ID chiến dịch là bắt buộc',
  THE_BUDGET_IS_REQUIRE: 'Ngân sách là bắt buộc',
  THE_VALUE_TYPE_NOT_EXISTS: 'Giá trị loại không tồn tại',
  MAX_CONVERSION_COST_REQUIRE: 'Chi phí chuyển đổi tối đa là bắt buộc',
  MINIMUMCTR_REQUIRE: 'Tỷ lệ nhấp chuột tối thiểu là bắt buộc',
  ADS_CONTENT_INVALID: 'Nội dung quảng cáo không hợp lệ',
  ADS_CONTENT_EMPTY_OR_INVALID: 'Nội dung quảng cáo trống hoặc không hợp lệ',
  CREATE_CAMPAIGN_SUCCESSFULLY: 'Tạo chiến dịch thành công',
  CREATE_CAMPAIGN_FAILED: 'Tạo chiến dịch thất bại',
  THE_TITLE_REQUIRE: 'Tiêu đề là bắt buộc',
  THE_MESSAGE_REQUIRE: 'Thông điệp là bắt buộc',
  THE_BANNER_IMAGE_REQUIRE: 'Hình ảnh biểu ngữ là bắt buộc',
  THE_BANNER_SIZE_REQUIRE: 'Kích thước biểu ngữ là bắt buộc',
  THE_ICON_REQUIRE: 'Biểu tượng là bắt buộc',
  THE_HEO_IMAGE_REQUIRE: 'Hình ảnh HEO là bắt buộc',
  UPLOAD_IMAGE_ONLY: 'Chỉ tải lên hình ảnh',
  UPDATE_CAMPAIGN_GROUP_SUCCESSFULLY: 'Cập nhật nhóm chiến dịch thành công',
  UPDATE_CAMPAIGN_GROUP_FAILED: 'Cập nhật nhóm chiến dịch thất bại',
  CAMPAIGN_GROUP_IS_USING_CAN_NOT_DELETE: 'Nhóm chiến dịch đang được sử dụng, không thể xóa',
  DELETE_CAMPAIGN_GROUP_SUCCESSFULLY: 'Xóa nhóm chiến dịch thành công',
  DELETE_CAMPAIGN_GROUP_FAILED: 'Xóa nhóm chiến dịch thất bại',
  CAMPAIGN_NOT_FOUND: 'Không tìm thấy chiến dịch',
  WEBSITE_ADS_NOT_APPROVED: 'Quảng cáo trên trang web chưa được phê duyệt',
  WEBSITEADS_NOT_FOUND: 'Không tìm thấy quảng cáo trên trang web',
  CAMPAIGN_ID_IS_REQUIRE: 'ID chiến dịch là bắt buộc',
  DELETE_CAMPAIGN_FAILED: 'Xóa chiến dịch thất bại',
  DELETE_CAMPAIGN_SUCCESSFULLY: 'Xóa chiến dịch thành công',
  THE_CPM_REQUIRE: 'CPM là bắt buộc',
  UPDATE_CAMPAIGN_SUCCESSFULLY: 'Cập nhật chiến dịch thành công',
  UPDATE_CAMPAIGN_FAILED: 'Cập nhật chiến dịch thất bại',
  GET_DETAIL_CAMPAIGN_FAILED: 'Lấy chi tiết chiến dịch thất bại',
  GET_DETAIL_CAMPAIGN_SUCCESSFULLY: 'Lấy chi tiết chiến dịch thành công',
  GET_LIST_OS_NAME_SUCCESSFULLY: 'Lấy danh sách tên hệ điều hành thành công',
  GET_LIST_VERSION_SUCCESSFULLY: 'Lấy danh sách phiên bản thành công',
  GET_DETAIL_CAMPAIGN_GROUP_SUCCESSFULLY: 'Lấy chi tiết nhóm chiến dịch thành công',
  THE_USER_ID_IS_REQUIRE: 'ID người dùng là bắt buộc',
  UPDATE_STATUS_TICKET_SUCCESSFULLY: 'Cập nhật trạng thái vé thành công',
  UPDATE_TICKET_SUCCESSFULLY: 'Cập nhật vé thành công',
  CAN_NOT_UPDATE_TICKET: 'Không thể cập nhật vé',
  ASSIGN_TICKET_SUCCESSFULLY: 'Giao vé thành công',
  GET_DETAIL_TICKET_SUCCESS: 'Lấy chi tiết vé thành công',
  TICKET_NOT_FOUND: 'Không tìm thấy vé',
  THE_VALUE_TYPE_NOT_EXISTS: 'Giá trị loại không tồn tại',
  THE_SUBJECT_TICKET_IS_REQUIRE: 'Chủ đề vé là bắt buộc',
  THE_TYPE_TICKET_REQUIRE: 'Loại vé là bắt buộc',
  THE_CONTENT_TICKET_IS_REQUIRE: 'Nội dung vé là bắt buộc',
  CREATE_TICKET_SUCCESFULLY: 'Tạo vé thành công',
  CLOSE_TICKET_SUCCESFULLY: 'Đóng vé thành công',
  OPEN_TICKET_SUCCESFULLY: 'Mở vé thành công',
  THE_VALUE_STATUS_NOT_EXISTS: 'Giá trị trạng thái không tồn tại',
  THE_STATUS_TICKET_REQUIRE: 'Trạng thái vé là bắt buộc',
  CREATE_GROUP_SUCCESFULLY: 'Tạo nhóm thành công',
  CREATE_GROUP_FAILED: 'Tạo nhóm thất bại',
  GROUP_NOT_FOUND: 'Không tìm thấy nhóm',
  UPDATE_GROUP_SUCCESFULLY: 'Cập nhật nhóm thành công',
  CAN_NOT_DELETE_GROUP: 'Không thể xóa nhóm',
  DELETE_GROUP_SUCCESSFULLY: 'Xóa nhóm thành công',
  THE_VALUE_GROUP_NAME_NOT_EXISTS: 'Tên nhóm không tồn tại',
};
