import { Avatar, Box, Button, Flex, FormControl, FormLabel, Image, Text } from '@chakra-ui/react';
import { useRef } from 'react';

const FileSelect = ({
  label,
  name,
  filePreview,
  error,
  imgHeight = '300px',
  leftAddon,
  styleButton,
  handleFileSelect,
  handleErrorFile,
  handleRemoveFile,
}) => {
  const inputRef = useRef();

  return (
    <FormControl display="flex" alignItems="center" isRequired>
      {!!label && (
        <FormLabel minW="150px" margin="0">
          {label}
        </FormLabel>
      )}
      {leftAddon && leftAddon}
      <Box alignItems="center">
        <Flex alignItems="center">
          <input
            type="file"
            hidden
            ref={inputRef}
            onChange={e => {
              handleFileSelect(name, e);
            }}
          />
          {!!filePreview ? (
            <Flex direction="column" alignItems="center" w="full">
              <Image boxSize="full" height={imgHeight} objectFit="cover" borderRadius="6px" src={filePreview} alt={name} />
              <Flex>
                <Text
                  ml={3}
                  fontSize="14px"
                  color="red.400"
                  cursor="pointer"
                  onClick={() => {
                    handleRemoveFile(name);
                  }}
                >
                  Remove
                </Text>
                <Text
                  ml={3}
                  fontSize="14px"
                  color="blue.400"
                  cursor="pointer"
                  onClick={() => {
                    handleErrorFile(name, null);
                    inputRef?.current?.click();
                  }}
                >
                  Change
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Button
              {...styleButton}
              variant="primary"
              maxH="30px"
              onClick={() => {
                handleErrorFile(name, null);
                inputRef?.current?.click();
              }}
            >
              Choose file
            </Button>
          )}
        </Flex>
        {!!error && (
          <Text pt={1} color={'red.500'} fontSize="13px">
            {error}
          </Text>
        )}
      </Box>
    </FormControl>
  );
};

export default FileSelect;
