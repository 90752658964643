import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getBanks = (params) => request.get(ROOT_API + '/api/v1/transactions/banks', { params });
export const getMyHistoryTransactions = (params) => request.get(ROOT_API + '/api/v1/my-histories', { params });
export const getMyTransactions = (params) => request.get(ROOT_API + '/api/v1/advertiser/my-transactions', { params });
export const createTransaction = data => request.post(ROOT_API + '/api/v1/transactions/create', data);
// Mutation
export const useCreateTransactionMutation = () => useMutation({ mutationFn: createTransaction });

// Query
export const useQueryGetBanks = (params = {}, options = {}) => useQuery({ queryKey: ['GET_BANKS', params], queryFn: () => getBanks(params), ...options });
export const useQueryGetMyHistoryTransactions = (params = {}, options = {}) => useQuery({ queryKey: ['getMyHistoryTransactions', params], queryFn: () => getMyHistoryTransactions(params), ...options });
export const useQueryGetMyTransactions = (params = {}, options = {}) => useQuery({ queryKey: ['GET_MY_TRASACTIONS', params], queryFn: () => getMyTransactions(params), ...options });
