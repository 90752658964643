import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { currencyFormat, formatDate } from 'utils/helpers';
import { isEmpty } from 'lodash';
import { TYPE_ACTION } from 'constants/common';

const DepositeTable = ({ data }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('channel', {
        header: 'Kênh giao dịch',
        cell: info => (info.getValue() || '--'),
      }),
      columnHelper.accessor('amount', {
        header: 'Số tiền giao dịch',
        cell: info => currencyFormat(info.getValue()),
      }),
      columnHelper.accessor('Thời gian giao dịch', {
        id: 'updatedAt',
        header: 'Thời gian giao dịch',
        cell: info => formatDate(info.getValue(), 'DD-MM-YYYY HH:mm'),
        footer: info => info.column.id,
      })
    ],
    [data]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box
                  // cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {/* {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null} */}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              <Box display="flex" justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                không có dữ liệu
              </Box>
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
      {/* <Tbody>
        {table.getRowModel().rows.map(row => (
          <Tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
            ))}
          </Tr>
        ))}
      </Tbody> */}
    </Table>
  );
};

export default DepositeTable;
