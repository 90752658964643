import { Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { FaPlus } from 'react-icons/fa6';
import FileSelect from 'components/Form/FileSelectController';
import InputController from 'components/Form/InputController';
import { TrackingParams } from 'constants/campaign';

const AdsContent = forwardRef(({ control, fileSelected, error, handleFileSelect, handleErrorFile }, ref) => {
  return (
    <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
      <Text fontSize="16px" fontWeight="700">
        NỘI DUNG QUẢNG CÁO
      </Text>
      <Divider my={15} />

      <Box>
        <InputController
          control={control}
          name="title"
          label="Tiêu đề"
          isRequired
          styleLabel={{ fontWeight: '700' }}
          styleContainer={{ pb: '4' }}
        />
        <InputController
          control={control}
          name="message"
          label="Mô tả"
          isRequired
          styleLabel={{ fontWeight: '700' }}
          styleContainer={{ pb: '4' }}
        />
        <InputController
          control={control}
          name="destinationURL"
          label="Liên kết đích"
          isRequired
          styleLabel={{ fontWeight: '700' }}
          styleContainer={{ pb: '4' }}
        />
        {/* <Flex flexDirection={{ base: 'column', xl: 'row' }} pb={4} gap={4}>
          <Box minW="120px">Tracking params:</Box>
          <Flex flexWrap="wrap" flex="1" gap="5px">
            {TrackingParams.map((item, index) => (
              <Flex
                key={index}
                alignItems="center"
                bgColor="#e1e5ec"
                borderColor="#e1e5ec"
                color="#666"
                padding="5px 10px"
                borderRadius="3px"
                fontSize="12px"
                cursor="pointer"
              >
                <FaPlus />
                <Text ml="4px" as="span">
                  {item.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Box pb={4}>
          <Text as="i" color="#337ab7" cursor="pointer" _hover={{ textDecoration: 'underline' }}>
            Tìm hiểu thêm về Tracking Params
          </Text>
        </Box> */}
        {/* <Box pb={4}>
          <Box>
            <Flex>
              <Text fontWeight="700">
                Ảnh lớn
                <Text as="span" color="#e53e3e" ml="3.5px">
                  *
                </Text>
              </Text>
              <Text ml={1}>(Tỉ lệ 3/2. Kích thước đề xuất 720x480px, tối đa 1MB)</Text>
            </Flex>
            <FileSelect
              name="heroImageFile"
              styleButton={{ borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0' }}
              leftAddon={
                <Box
                  px="14px"
                  flex={1}
                  display="flex"
                  alignItems="center"
                  h="30px"
                  bgColor="#eef1f5"
                  borderTopLeftRadius="5px"
                  borderBottomLeftRadius="5px"
                >
                  {fileSelected?.heroImageFile?.file?.name}
                </Box>
              }
              handleFileSelect={handleFileSelect}
              handleErrorFile={handleErrorFile}
            />
          </Box>
          <Box>
            {!!error.heroImageFile && (
              <Text pt={1} color={'red.500'} fontSize="13px">
                {error.heroImageFile}
              </Text>
            )}
          </Box>
        </Box> */}
        <Box pb={4}>
          <Box>
            <Flex>
              <Text fontWeight="700">
                Icon
                <Text as="span" color="#e53e3e" ml="3.5px">
                  *
                </Text>
              </Text>
              <Text ml={1}>(192x192px, tối đa 150KB)</Text>
            </Flex>
            <FileSelect
              name="iconFile"
              styleButton={{ borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0' }}
              leftAddon={
                <Box
                  px="14px"
                  flex={1}
                  display="flex"
                  alignItems="center"
                  h="30px"
                  bgColor="#eef1f5"
                  borderTopLeftRadius="5px"
                  borderBottomLeftRadius="5px"
                >
                  {fileSelected?.iconFile?.file?.name}
                </Box>
              }
              handleFileSelect={handleFileSelect}
              handleErrorFile={handleErrorFile}
            />
          </Box>
          <Box>
            {!!error.iconFile && (
              <Text pt={1} color={'red.500'} fontSize="13px">
                {error.iconFile}
              </Text>
            )}
          </Box>
        </Box>
        {/* <Box>
          <Flex flexDirection={{ base: 'column', xl: 'row' }}>
            <Text fontWeight="700">Nút hành động</Text>
            <Text ml={1}>(không bắt buộc - Chỉ có sẵn cho định dạng push notification)</Text>
          </Flex>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 2, xl: 1 }}>
              <InputController control={control} name="ButtonLeft" label="Nút 1" />
            </GridItem>
            <GridItem colSpan={{ base: 2, xl: 1 }}>
              <InputController control={control} name="ButtonRight" label="Nút 2" />
            </GridItem>
          </Grid>
        </Box> */}
      </Box>
    </Box>
  );
});

export default AdsContent;
