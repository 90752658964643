import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Select, chakraComponents } from 'chakra-react-select';

const components = {
  DropdownIndicator: (props) => (
    <chakraComponents.DropdownIndicator {...props}>
      <></>
    </chakraComponents.DropdownIndicator>
  )
};

const SelectController = ({
  name,
  label,
  control,
  isReadOnly = false,
  styleContainer,
  styleBoxInput,
  isRequired,
  placeholder,
  options,
  styleLabel,
  onChange,
  onBlur,
  styleInput,
  classNamePrefix,
  noIconDropDown,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => (
      <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}>
        {label && (
          <FormLabel {...styleLabel} minW="150px">
            {label}
          </FormLabel>
        )}
        <Box {...styleBoxInput} position="relative" borderRadius="12px">
          <Select
            classNamePrefix={classNamePrefix}
            focusBorderColor="#1DCA74"
            size="lg"
            _placeholder={{ opacity: 1, color: 'gray.300' }}
            {...field}
            {...props}
            onChange={e => {
              field.onChange(e);
              onChange?.(e);
            }}
            onBlur={e => {
              field.onBlur(e);
              onBlur?.(e);
            }}
            isReadOnly={isReadOnly}
            useBasicStyles
            options={options}
            placeholder={placeholder || 'Chọn'}
            chakraStyles={{
              input: (provided, state) => ({
                ...provided,
                zIndex: 10,
                // minHeight: '200px'
                paddingTop: '28px',
                paddingBottom: '0px',
                borderRadius: '24px',
                ...styleInput,
              }),
            }}
            components={noIconDropDown && components}
          />
          <FormErrorMessage>{error && error?.message}</FormErrorMessage>
        </Box>
      </FormControl>
    )}
  />
);

export default SelectController;
