import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';
import FileSelect from 'components/Form/FileSelectController';
import InputController from 'components/Form/InputController';

const AdsContent = forwardRef(({ control, fileSelected, error, handleFileSelect, handleErrorFile }, ref) => {
  return (
    <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
      <Text fontSize="16px" fontWeight="700">
        NỘI DUNG QUẢNG CÁO
      </Text>
      <Divider my={15} />

      <Box>
        <InputController
          control={control}
          name="title"
          label="Tiêu đề"
          isRequired
          styleLabel={{ fontWeight: '700' }}
          styleContainer={{ pb: '4' }}
        />
        <InputController
          control={control}
          name="destinationURL"
          label="Liên kết đích"
          isRequired
          styleLabel={{ fontWeight: '700' }}
          styleContainer={{ pb: '4' }}
        />
        <Box>
          <Box>
            <Flex>
              <Text fontWeight="700">
                Ảnh lớn
                <Text as="span" color="#e53e3e" ml="3.5px">
                  *
                </Text>
              </Text>
              <Text ml={1}>(Tỉ lệ 3/2. Kích thước đề xuất 720x480px, tối đa 1MB)</Text>
            </Flex>
            <FileSelect
              name="heroImageFile"
              styleButton={{ borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0' }}
              leftAddon={
                <Box
                  px="14px"
                  flex={1}
                  display="flex"
                  alignItems="center"
                  h="30px"
                  bgColor="#eef1f5"
                  borderTopLeftRadius="5px"
                  borderBottomLeftRadius="5px"
                >
                  {fileSelected?.heroImageFile?.file?.name}
                </Box>
              }
              handleFileSelect={handleFileSelect}
              handleErrorFile={handleErrorFile}
            />
          </Box>
          <Box>
            {!!error.heroImageFile && (
              <Text pt={1} color={'red.500'} fontSize="13px">
                {error.heroImageFile}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default AdsContent;
