
import { BankLogo, BankStatus } from 'constants/bank';
import { OSFullName } from 'constants/campaign';

export const mappingOptionSelect = (data, labelKey = 'name', valueKey = '_id') =>
  data?.map(item => ({
    label: item?.[labelKey],
    value: item?.[valueKey],
  }));

export const mappingCountries = data =>
  data?.map(item => ({ label: `${item?.code} - ${item?.name}`, value: item?.code, name: item?.name }));

export const mappingOSNameOptions = data => Object.keys(data).map(key => ({ label: OSFullName[key], value: key }));

export const mappingBank = data =>
  data
    .map(item => {
      if (item.status === BankStatus.Available ||item.status === BankStatus.InMaintenance) {
        return {
          ...item,
          logo: BankLogo[item.bankCode],
        };
      }
      return undefined;
    })
    ?.filter(item => !!item);
