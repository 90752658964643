import React, { useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Stack, Switch, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { Select } from 'chakra-react-select';
import { StatusTicketOptions, TYPE_ACTION } from 'constants/common';
import TicketForm from './components/TicketForm';
import { useQueryGetCategoryTicket, useQueryGetTickets } from 'services/ticket';
import TicketTable from './components/TicketTable';
import { isEmpty } from 'lodash';
import DatePicker from 'components/DatePicker/DatePicker';
import InputSearch from 'components/InputSearch/InputSearch';
import MultiSelectMenu from 'components/MultiSelect';
import { mappingOptionSelect } from 'utils/mapping';


export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

function Support() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [editTicket, seteditTicket] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  // const [ticketType, setTicketType] = useState(null);
  // console.log(ticketType, 'ticketType')
  const [ticketStatus, setTicketStatus] = useState(null);
  // const [switched, setSwitched] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterDate, setFilterDate] = useState({ startDate: null, endDate: null });
  const [isOpenMultiSelect, setIsOpenMultiSelect] = useState(false);
  const [isOpenMultiSelectStatus, setIsOpenMultiSelectStatus] = useState(false);
  const [filterTickets, setFilterTickets] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const { data: categories } = useQueryGetCategoryTicket();

  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();

  const { data, refetch } = useQueryGetTickets({
    ...filter,
  });
  const { data: tickets, pagination } = data || {};

  const onFilter = () => {
    setFilter({
      ...filter,
      ...filterDate,
      searchKeyword,
      type: filterTickets.map(item => item.value),
      status: filterStatus?.map(item => item?.value),
    });
  };

  const onReset = () => {
    setFilter(initialFilter);
    setFilterTickets([]);
    setFilterStatus([]);
    setTicketStatus(null);
    setFilterDate({ startDate: null, endDate: null });
  };

  const handelUpdateTicket = (editTicket, type) => {
    if (type === TYPE_ACTION.UPDATE) {
      onRegisterOpen();
      seteditTicket(editTicket);
    }
  };

  const handelCloseModal = () => {
    seteditTicket(null);
    onRegisterClose();
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };

  const onChangeSearch = event => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const handleBlurTicket = values => {
    setFilterTickets(values)
  }

  const handleBlurStatus = values => {
    setFilterStatus(values)
  }
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              {/* <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Ticket
                </Text>
              </Flex> */}
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'}>
                <Stack>
                  <Flex alignItems={'end'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                      <FormLabel>Tìm kiếm theo tiêu đề</FormLabel>
                      <InputSearch value={searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                    </FormControl>
                    <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                      <FormLabel>Loại Ticket</FormLabel>
                      <MultiSelectMenu
                        label="Chọn ticket"
                        options={mappingOptionSelect(categories?.data) || []}
                        onBlur={handleBlurTicket}
                        minWidth={{ base: '200px', sm: '300px' }}
                        onClose={() => setIsOpenMultiSelect(false)}
                        onOpen={() => setIsOpenMultiSelect(true)}
                        handleDeleteItemSelected={() => setFilterTickets([])}
                        isOpen={isOpenMultiSelect}
                        filterTickets={filterTickets}
                      />
                    </FormControl>
                    <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                      <FormLabel>Trạng thái</FormLabel>
                      <MultiSelectMenu
                        label="Chọn trạng thái"
                        options={StatusTicketOptions}
                        onBlur={handleBlurStatus}
                        minWidth={{ base: '200px', sm: '300px' }}
                        onClose={() => setIsOpenMultiSelectStatus(false)}
                        onOpen={() => setIsOpenMultiSelectStatus(true)}
                        handleDeleteItemSelected={() => setFilterStatus([])}
                        isOpen={isOpenMultiSelectStatus}
                        filterTickets={filterStatus}
                      />
                    </FormControl>
                  </Flex>
                  <Flex flexWrap="wrap" marginTop={4} gap={'20px'}>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                      <FormLabel m="0">Từ ngày</FormLabel>
                      <DatePicker selectedDate={filterDate.startDate} onChange={date => onChangeDate('startDate')(date)} />
                    </FormControl>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                      <FormLabel m="0">Đến ngày</FormLabel>
                      <DatePicker
                        selectedDate={filterDate.endDate}
                        minDate={filterDate.startDate}
                        onChange={date => onChangeDate('endDate')(date)}
                      />
                    </FormControl>
                    <Button
                      backgroundColor="white"
                      color="blue.400"
                      border="1px"
                      _hover={{
                        text: 'white',
                      }}
                      maxH="30px"
                      mx="0px"
                      fontSize="14px"
                      alignSelf={'end'}
                      px={8}
                      onClick={onReset}
                    >
                      Đặt lại
                    </Button>
                    <Button variant="primary" minW={50} maxW={150} maxH="30px" fontSize="14px" onClick={onFilter} alignSelf={'end'}>
                      Lọc
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
            <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onRegisterOpen}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Thêm
              </Text>
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack overflow={'auto'}>
            <TicketTable tickets={tickets || []} handelUpdateTicket={handelUpdateTicket} />
          </Stack>
          {!isEmpty(tickets) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={pagination?.page}
                pageLength={pagination?.pageSize}
                totalRecords={pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
      {isRegisterOpen && <TicketForm editTicketDetail={editTicket} refetch={refetch} isOpen={isRegisterOpen} onClose={handelCloseModal} typeTicket={mappingOptionSelect(categories?.data)} />}
    </Flex>
  );
}

export default Support;