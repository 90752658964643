import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    createColumnHelper,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { formatDate } from 'utils/helpers';

const HistoryRechangeTable = ({ transactions }) => {
    const [sorting, setSorting] = useState([]);
    const columnHelper = createColumnHelper();

    const columns = useMemo(
        () => [
            columnHelper.accessor('createdAt', {
                header: 'Time',
                cell: info => formatDate(info.getValue()),
                footer: info => info.column.id,
            }),
            columnHelper.accessor('type', {
                header: 'Status',
                cell: info => info.renderValue(),
                footer: <span>{0}</span>,
            }),
            columnHelper.accessor('amount', {
                header: () => 'Amount',
                cell: info => info.renderValue(),
                footer: <span>{0}</span>,
            }),
        ],
        [transactions]
    );

    const table = useReactTable({
        data: transactions || [],
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <Th key={header.id} w="120px">
                                {header.isPlaceholder ? null : (
                                    <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: ' 🔼',
                                            desc: ' 🔽',
                                        }[header.column.getIsSorted()] ?? null}
                                    </Box>
                                )}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map(row => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

export default HistoryRechangeTable;
