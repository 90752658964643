import React, { useState } from "react";
import { GiMoneyStack } from "react-icons/gi";
import { Box, Button, Flex, Image, Input, InputGroup, InputLeftElement, Text } from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";
import { useUserState } from "context/UserContext";
import { formatDate } from "utils/helpers";
import { ErrorForm } from "constants/error";
import { inputAmountMoneyValidate } from "utils/validation";
import { useCreateTransactionMutation, useQueryGetBanks } from "services/transaction";
import { BankMethod, BankStatus, MethodsText, PaymentGateway } from "constants/bank";
import BankList from "./BankList";
import { toast } from "components/Toast";

function Rechange() {
    const { userInfo } = useUserState();
    const [methodCurrent, setMethodCurrent] = useState("");
    const [bankSelected, setBankSelected] = useState("");
    const [amountMoney, setAmountMoney] = useState(0);
    const [error, setError] = useState(null);
    const createTransaction = useCreateTransactionMutation();

    const {
        data: dataBanks
    } = useQueryGetBanks({
    });
    const { data: banks } = dataBanks || {};

    const handlePayment = () => {
        if (isEmpty(amountMoney)) {
            setError(ErrorForm.Required)
            return;
        }
        if (BankMethod.includes(methodCurrent?.channel) && isEmpty(bankSelected)) {
            toast.showMessageInfo("Vui lòng chọn ngân hàng.")
            return;
        }
        createTransaction.mutate(
            {
                amount: amountMoney,
                channel: methodCurrent.channel,
                result_url: `http://${window.location.host}/admin/profile`,
                ...(!isEmpty(bankSelected) && { bank_code: bankSelected.bankCode }),
            },
            {
                onSuccess: response => {
                    setAmountMoney(0);
                    const { data } = response?.data;
                    window.location.href = data;
                },
            }
        );
    }

    const onSelectBank = bank => {
        setBankSelected(bank);
    };

    return (
        <>
            <Flex bg={"#E2E2E2"} rounded={"20px"} padding={"20px"} gap={"20px"}>
                <Image rounded={"20px"} src='gibbresh.png' fallbackSrc='https://via.placeholder.com/150' />
                <Flex direction={"column"} gap={"16px"}>
                    <Flex color={"#464646"} fontSize={"18px"}>Joining date:&nbsp;<Text display={"inline"} fontWeight={"600"}>{formatDate(userInfo.createdAt)}</Text></Flex>
                    <Flex color={"#464646"} fontSize={"18px"}>Referral code:&nbsp;<Text display={"inline"} fontWeight={"600"}>{userInfo.referralCode}</Text></Flex>
                    <Flex color={"#464646"} fontSize={"18px"}>Wallet balance:&nbsp;<Text display={"inline"} fontWeight={"600"}>{userInfo.balance}₫</Text></Flex>
                </Flex>
            </Flex>
            <Flex direction={"column"} mt={10}>
                <Text fontWeight={"600"} fontSize={"20px"} mb={"20px"}>Phương thức thanh toán:</Text>
                <Flex fontWeight={"600"} wrap={"wrap"} gap={"1.25rem"} fontSize={"20px"} color={"#3182CE"}>
                    {
                        banks?.filter(item => item.isOnline === BankStatus.Available && ![PaymentGateway.Bank, PaymentGateway.UsdtQr].includes(item.channel)).map((method, index) => (
                            <Box key={index} onClick={() => {
                                setMethodCurrent(method)
                                setBankSelected("")
                                setError("")
                            }} bg={methodCurrent.channel === method.channel ? "#3182CE" : null} color={methodCurrent.channel === method.channel ? "white" : null} w='268px' h='62px' border='1px solid #ccc' rounded={"8px"} lineHeight={"62px"} textAlign={"center"} cursor={"pointer"} _hover={{
                                backgroundColor: '#3182CE',
                                color: "white"
                            }}>{MethodsText[method.channel]}</Box>
                        ))
                    }
                </Flex>
            </Flex>
            {methodCurrent?.bankVOList && (
                <BankList bankList={methodCurrent?.bankVOList} bankSelected={bankSelected} onSelectBank={onSelectBank} />
            )}
            <Flex mt={10} borderTop={"1px solid #CCC"} justifyContent={"center"} gap={5} pt={10}>
                <Flex direction={"column"} w={"40%"} gap={5}>
                    <Text fontWeight={600} fontSize={16}>Số tiền</Text>
                    <InputGroup h={"50px"}>
                        <InputLeftElement pointerEvents='none' h={"50px"}>
                            <GiMoneyStack />
                        </InputLeftElement>
                        <Input value={amountMoney} h={"50px"} type='number' placeholder='Số tiền cần nạp' onChange={(event) => {
                            setError(inputAmountMoneyValidate(event.target.value))
                            setAmountMoney(event.target.value)
                        }} />
                    </InputGroup>
                    {
                        error !== 0 && <Text color='tomato'>{error}</Text>
                    }
                    <Button bg={"#3182CE"} color={"white"} h={"50px"} onClick={handlePayment}>Nạp tiền</Button>
                </Flex>
                <Flex direction={"column"} gap={3} borderLeft={"1px solid #ccc"} pl={5}>
                    <Text fontWeight={600} fontSize={16}>Lưu ý:</Text>
                    <Text>1. Vui lòng điền chính xác số tiền bạn muốn giao dịch.</Text>
                    <Text>2. Để tiền được cập nhất nhanh nhất, quý khách vui lòng không sửa đội nội dung chuyển tiền.</Text>
                    <Text>3. Vui lòng chỉ quét mã QR 1 lần duy nhất, không lặp lại để tránh sai sót.</Text>
                    <Text>4. Mã QR sẽ không thể hỗ trợ thanh toán cho một số ít ngân hàng nhất định. Mọi trường hợp cần hỗ trợ vui lòng liên hệ CSKH 24/7.</Text>
                </Flex>
            </Flex>
        </>
    )
}

export default Rechange