import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
const { Flex, useColorModeValue, Text, Stack } = require("@chakra-ui/react")

import { useState } from "react";
import { InitFilter } from "constants/common";

import { useGetCampaignsQuery } from "services/campaign";
import { isEmpty } from "lodash";
import TableCampaigns from "./components/Table";
import Pagination from "components/Pagination/Pagination";
import Headers from "./components/Headers";

const initialFilter = {
  ...InitFilter,
  searchKeyword: ''
}

const Campaigns = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState(initialFilter);
  const { data, refetch } = useGetCampaignsQuery(filter);
  const { data: campaigns, pagination } = data || {};

  const handleFilter = params => {
    setFilter({
      ...filter,
      searchKeyword: params,
      pageIndex: 0,
    });
  }

  const handleReset = () => {
    setFilter(initialFilter);
  }

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px" bgColor="white">
        {/* <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Danh sách chiến dịch
          </Text>
        </CardHeader> */}
        <CardBody>
          <Headers
            handleReset={handleReset}
            handleFilter={handleFilter}
          />
          <Stack overflow={'auto'}>
            <TableCampaigns
              campaigns={campaigns || []}
              refetchData={refetch}
            />
          </Stack>

          {!isEmpty(campaigns) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={pagination?.page}
                pageLength={pagination?.pageSize}
                totalRecords={pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter(prev => ({
                    ...prev,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  }));
                }}
              />
            </Flex>
          )}
        </CardBody>


      </Card>
    </Flex>
  )
};

export default Campaigns;