import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { PushAdsValidate } from 'utils/validation';
import { FileImageValid, InitFilter, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import Preview from './components/Preview';
import {
  createPushAdsCampaign,
  updatePushAdsCampaign,
  useGetCampaignDetailQuery,
  useGetCampaignGroupQuery,
  useGetCategoriesQuery,
  useGetCityMutation,
  useGetCountriesQuery,
} from 'services/campaign';
import { ErrorForm } from 'constants/error';
import AdsInfo from 'components/CampaignDetails/AdsInfo';

import AdsFormat from 'components/CampaignDetails/AdsFormat';
import BidAndTarget from 'components/CampaignDetails/BidAndTarget';
import { AdsType, Devices, ScheduleHourData } from 'constants/campaign';
import { getCampaignDevices } from 'utils/helpers';
import { mappingCountries, mappingOptionSelect } from 'utils/mapping';
import OtherSetting from 'components/CampaignDetails/OtherSetting';
import AdsContent from './components/AdsContent';

const DataOmit = ['heroImage', 'icon', 'createdAt', 'updatedAt', '__v', '__id', 'devices', 'type', '_id', 'campaignGroup', 'scheduleHour'];

const Interstitial = () => {
  const bidAndTargetRef = useRef();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { id } = params || {};
  const [scheduleHour, setScheduleHour] = useState([]);
  const [fileSelected, setFileSelected] = useState({
    heroImageFile: null,
    iconFile: null,
  });
  const [error, setError] = useState({
    heroImageFile: null,
    iconFile: null,
  });
  const { data: categories } = useGetCategoriesQuery();
  const { data: countries } = useGetCountriesQuery();
  const { data: campaignGroups } = useGetCampaignGroupQuery();
  const { data: campaignDetail } = useGetCampaignDetailQuery(id, {
    enabled: !!id,
  });
  const countryOptions = useMemo(() => mappingCountries(countries?.data || []), [countries?.data]);
  const campaignGroupOptions = useMemo(() => mappingOptionSelect(campaignGroups?.data, 'groupName', '_id'), [campaignGroups?.data]);

  const { control, watch, handleSubmit, trigger, setValue, reset } = useForm({
    resolver: yupResolver(PushAdsValidate),
    defaultValues: {
      campaignName: '',
      campaignGroupID: undefined,
      budget: false,
      title: '',
      message: '',
      destinationURL: '',
      countryCode: undefined,
      ButtonLeft: '',
      ButtonRight: '',
      // startTime: undefined,
      // endTime: undefined,
    },
  });

  useEffect(() => {
    if (!isEmpty(campaignDetail?.data)) {
      const { data } = campaignDetail || {};

      reset({
        ...omit(data, DataOmit),
        categories: mappingOptionSelect(data?.categories || [], 'name', 'slug'),
        cities: data?.cities.map(item => ({ label: item, value: item })),
        countryCode: countryOptions?.find(item => item?.value === data?.countryCode),
        campaignGroupID: campaignGroupOptions?.find(item => item?.value === data?.campaignGroup?._id),
      });
      setFileSelected({
        heroImageFile: {
          filePreview: data?.heroImage ? ROOT_API + data?.heroImage : null,
        },
        iconFile: {
          filePreview: data?.icon ? ROOT_API + data?.icon : null,
        },
      });
      setScheduleHour(ScheduleHourData.filter(item => data.scheduleHour?.includes(item.value)).map(item => item.value));

      handleInitDevicesValue(data?.devices);

      !isEmpty(data?.cities) && bidAndTargetRef.current?.getCityOptions(data?.countryCode, data?.cities);
    }
  }, [campaignDetail?.data, countryOptions, campaignGroupOptions, bidAndTargetRef]);

  const handleInitDevicesValue = devices => {
    if (devices?.length === 3) {
      setValue('allDevices', true);
      setValue('isDesktop', true);
      setValue('isSmartphone', true);
      setValue('isTablet', true);
    } else {
      setValue('isDesktop', devices.includes(Devices.Desktop));
      setValue('isSmartphone', devices.includes(Devices.Smartphone));
      setValue('isTablet', devices.includes(Devices.Tablet));
    }
  };

  const onSubmit = async dataForm => {
    try {
      if (!fileSelected.heroImageFile?.filePreview) {
        handleErrorFile('heroImageFile', ErrorForm.Required);
        return;
      }
      const devices = getCampaignDevices({
        isAll: dataForm?.allDevices,
        isDesktop: dataForm?.isDesktop,
        isSmartphone: dataForm?.isSmartphone,
        isTablet: dataForm?.isTablet,
      });

      const formData = new FormData();
      console.log(dataForm, 'dataForm')
      devices.forEach(item => formData.append('devices[]', item));
      formData.append('type', AdsType.Interstitial);
      for (let key in dataForm) {
        if (!isUndefined(dataForm[key]) && !isNull(dataForm[key])) {
          isArray(dataForm[key])
            ? dataForm[key].forEach(item => formData.append(key + '[]', isObject(item) ? item?.value : item))
            : isObject(dataForm[key])
            ? formData.append(key, dataForm[key]?.value)
            : formData.append(key, dataForm[key]);
        }
      }
     
      formData.append('country', formData?.countryCode?.name);
      !!fileSelected.heroImageFile?.file && formData.append('heroImage', fileSelected.heroImageFile?.file);
      !!fileSelected.iconFile?.file && formData.append('icon', fileSelected.iconFile?.file);
      !isEmpty(scheduleHour) && scheduleHour?.map(item => formData.append('scheduleHour[]', item));
      !!id && formData.append('campaignID', id);

      const response = await (id ? updatePushAdsCampaign(formData) : createPushAdsCampaign(formData));

      if (response?.code === 0) {
        toast.showMessageSuccess(`${!!id ? 'Câp nhập' : 'Tạo'} chiến dịch Interstitial Ads thành công.`);
        history.push(`/admin/campaign-groups/${dataForm.campaignGroupID?.value}/list`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name?.split('.')?.pop()?.toLowerCase();

      if ([...FileImageValid, 'gif'].includes(extensionFile)) {
        setFileSelected(prev => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));

        return;
      }

      setFileSelected(prev => ({
        ...prev,
        [fieldName]: null,
      }));
      handleErrorFile(fieldName, 'File reload formats are supported only .png, .jpeg, .jpg');
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bgColor="#e9ecf3">
        <CardBody pb={4} position="relative">
          <Flex flexDirection={{ base: 'column', xl: 'row' }} gap="24px">
            <Box width={{ base: '100%' }}>
              <form>
                <AdsInfo
                  control={control}
                  categories={categories?.data}
                  campaignGroupOptions={campaignGroupOptions}
                  watch={watch}
                  trigger={trigger}
                  setValue={setValue}
                />
                <AdsContent
                  control={control}
                  fileSelected={fileSelected}
                  error={error}
                  handleFileSelect={handleFileSelect}
                  handleErrorFile={handleErrorFile}
                />
                {/* <AdsFormat control={control} /> */}
                <BidAndTarget ref={bidAndTargetRef} control={control} countryOptions={countryOptions} setValue={setValue} />
                {/* <OtherSetting control={control} scheduleHour={scheduleHour} setScheduleHour={setScheduleHour} /> */}
              </form>
              <Flex pt={4}>
                <Button colorScheme="blue" onClick={handleSubmit(onSubmit)}>
                  {`${!!id ? 'Câp nhập' : 'Tạo'} chiến dịch`}
                </Button>
              </Flex>
            </Box>
            {/* <Box width={{ base: '100%', xl: '35%' }}>
              <Preview fileSelected={fileSelected} watchForm={watch} />
            </Box> */}
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default Interstitial;
