import { Box, Flex, IconButton, Table, Tbody, Td, Tfoot, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table';
import { Fragment, useMemo, useState } from 'react';
import sum from 'lodash/sum';
import map from 'lodash/map';
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import { useDeleteCampaignMutation } from 'services/campaign';
import { isEmpty } from 'lodash';
import { toast } from "components/Toast";
import DetailCampaign from '../DetailCampaign';

const defaultData = [];

const TableCampaigns = ({ campaigns, refetchData }) => {
  const history = useHistory();
  const [currentItemId, setCurrentItemId] = useState(null);
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const [campaignDetail, setCampaignDetail] = useState();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure();
  const deleteCampaignMutation = useDeleteCampaignMutation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('_id', {
        header: 'ID',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('campaignName', {
        header: 'Tên quảng cáo',
        cell: info => <Box minWidth={"150px"}>{info.getValue()}</Box>,
      }),
      columnHelper.accessor('categories', {
        header: 'Danh mục website',
        cell: info => <Box minWidth={"150px"}>{info.getValue()?.map(item => item.name)?.toString()}</Box>,
      }),
      columnHelper.accessor('type', {
        header: 'Loại chiến dịch',
        cell: info => info.renderValue(),
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => <Box minWidth={"70px"}>{info.getValue()}</Box>,
      }),
      columnHelper.accessor('campaignGroup.groupName', {
        header: 'Nhóm chiến dịch',
        cell: info => <Box minWidth={"150px"}>{info.getValue()}</Box>,
      }),
      columnHelper.accessor('countClick', {
        header: 'Clicks',
        cell: info => info.renderValue(),
        footer: <span>{sum(map(campaigns, d => d.countClick)) || 0}</span>,
      }),
      columnHelper.accessor('budget', {
        header: 'Chi phí',
        cell: info => (info.getValue() ? 'Không giới hạn' : info?.row?.original?.dailyBudget || '--'),
      }),
      columnHelper.accessor('totalBudget', {
        header: 'Tổng chi phí',
        cell: info => <Box minWidth={"120px"}>{info.renderValue() || 0}</Box>,
        footer: <span>{sum(map(defaultData, d => d.totalBudget))}</span>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex gap={3}>
            <IconButton
              onClick={() => {
                setCampaignDetail(info?.row?.original);
                onOpenDetail();
              }}
            >
              <EditIcon
                cursor="pointer"
                boxSize={4}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setCurrentItemId(info?.row?.original?._id);
                onConfirmOpen();
              }}
            >
              <DeleteIcon cursor="pointer" color="red.500" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [campaigns]
  );

  const table = useReactTable({
    data: campaigns || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const onDeleteCampaignGroup = () => {
    if (currentItemId) {
      deleteCampaignMutation.mutate(
        { campaignID: currentItemId },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa chiến dịch thành công.');
            onConfirmClose();
            setCurrentItemId(null);
            refetchData?.();
          },
          onError: () => {
            onConfirmClose();
            setCurrentItemId(null);
          },
        }
      );
    }
  };

  return (
    <Box width={"100%"} overflowX={isEmpty(campaigns) ? "unset" : "auto"} className='custom-scrollbar-track'>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px">
                  {header.isPlaceholder ? null : (
                    <Box
                    // cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null} */}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={13}>
                <Box display="flex" justifyContent={"center"} alignItems={"center"} textAlign="center" height="200px">
                  không có dữ liệu
                </Box>
              </Td>
            </Tr>
          ) :
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
      <ConfirmDialog
        title="Thông tin chiến dịch"
        content={<DetailCampaign {...campaignDetail} />}
        confirmBtn=""
        isOpen={isOpenDetail}
        onClose={onCloseDetail}
      />
      <ConfirmDialog
        title="Xóa chiến dịch"
        content="Bạn có chắc chắn muốn xóa chiến dịch này không?"
        confirmBtn="Xóa"
        width="450px"
        isOpen={isConfirmOpen}
        isDeleting={deleteCampaignMutation.isPending}
        onClose={onConfirmClose}
        onConfirm={onDeleteCampaignGroup}
      />
    </Box>
  );
};

export default TableCampaigns;
