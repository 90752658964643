import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import NumericFormat from '../NumericFormat';

const NumericInputController = ({
  name,
  control,
  placeholder,
  label,
  onChange,
  suffix,
  suffixClassName,
  maxValue,
  styleContainer,
  styleLabel,
  isRequired,
  styleBoxInput,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => (
      <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}>
        <FormLabel {...styleLabel} minW="150px">
          {label}
        </FormLabel>
        <Box {...styleBoxInput}>
          <NumericFormat
            id={name}
            className={classNames('form-control input-numeric', {
              'pr-10': !!suffix,
              'is-error': error,
            })}
            valueIsNumericString
            placeholder={placeholder}
            value={field.value}
            isAllowed={({ floatValue }) => {
              return !maxValue ? true : floatValue !== undefined ? floatValue <= maxValue : true;
            }}
            onFocus={field.onFocus}
            onBlur={field.onBlur}
            onValueChange={({ value: newValue }) => {
              onChange?.(newValue);
              field.onChange(newValue);
            }}
            getInputRef={field.ref}
            {...props}
          />
          {!!suffix && (
            <div className={classNames('absolute w-10 top-0 bottom-0 m-auto right-0 flex items-center justify-center', suffixClassName)}>
              <span>{suffix}</span>
            </div>
          )}
        </Box>
        <FormErrorMessage>{error && error?.message}</FormErrorMessage>
      </FormControl>
    )}
  />
);

export default NumericInputController;
