import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as InputComponent,
  InputGroup,
  InputRightElement,
  Textarea,
} from '@chakra-ui/react';
import { BiSolidHide, BiSolidShow  } from "react-icons/bi";

const InputController = ({
  type = 'text',
  hidden = false,
  readOnly = false,
  placeholder,
  name,
  label,
  control,
  styleContainer,
  styleBoxInput,
  styleLabel,
  isRequired,
  disabled,
  extendsComponent,
  onChange,
  requiredIndicator,
  hasIconPassword,
  ...props
}) => {
  const [show, setShow] = React.useState(false);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}>
          {label && (
            <FormLabel requiredIndicator={requiredIndicator} {...styleLabel} minW="150px">
              {label}
            </FormLabel>
          )}
          <Box {...styleBoxInput}>
            <Flex flexDirection="column">
              {type === 'textarea' ? (
                <Textarea
                  {...field}
                  {...props}
                  className="form-control"
                  type={type}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={e => {
                    onChange?.(e);
                    field.onChange(e);
                  }}
                />
              ) : type === 'password' && hasIconPassword ? (
                <InputGroup>
                  <InputRightElement pointerEvents="auto" cursor="pointer" children={show ? <BiSolidShow color="gray.300" /> : <BiSolidHide color="gray.300" />} size="xs" onClick={() => {
                    setShow(!show)
                  }} />
                  <InputComponent
                    {...field}
                    {...props}
                    readOnly={readOnly}
                    hidden={hidden}
                    type={show ? 'text' : type}
                    disabled={disabled}
                    onChange={e => {
                      onChange?.(e);
                      field.onChange(e);
                    }}
                  />
                </InputGroup>
              ) : (
                <InputComponent
                  {...field}
                  {...props}
                  readOnly={readOnly}
                  hidden={hidden}
                  type={type}
                  disabled={disabled}
                  onChange={e => {
                    onChange?.(e);
                    field.onChange(e);
                  }}
                />
              )}
              <FormErrorMessage>{error && error?.message}</FormErrorMessage>
            </Flex>
            {extendsComponent && <Box>{extendsComponent}</Box>}
          </Box>
        </FormControl>
      )}
    />
  )
};

export default InputController;
