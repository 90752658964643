import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getTickets = (params) => request.get(ROOT_API + '/api/v1/advertiser/ticket', { params });
export const createTicket = data => request.post(ROOT_API + '/api/v1/advertiser/ticket', data);
export const updateTicket = payload => request.post(ROOT_API + `/api/v1/advertiser/ticket/${payload.id}`, payload.data);
export const listCategoryTicket = params => request.get(ROOT_API + '/api/v1/ticket-categories', { params });

// Mutation
export const useCreateTicketMutation = () => useMutation({ mutationFn: createTicket });
export const useUpdateTicketMutation = () => useMutation({ mutationFn: updateTicket });

// Query
export const useQueryGetTickets = (params = {}, options = {}) => useQuery({ queryKey: ['getTickets', params], queryFn: () => getTickets(params), ...options });
export const useQueryGetCategoryTicket = (params = {}, options = {}) => useQuery({ queryKey: ['getListCategoryTicket', params], queryFn: () => listCategoryTicket(params), ...options });
