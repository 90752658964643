import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { ROOT_API } from 'constants/common';
import { isEmpty } from 'lodash';
import React from 'react';
import { formatDate } from 'utils/helpers';

const DetailCampaign = (detail) => {
  console.log(detail, 'detail')

  const RenderImage = data => {
    if (data?.heroImage) {
      return <Image height={100} w={180} objectFit={"contain"} src={ROOT_API + data.heroImage} alt="image-hero" maxWidth="100%" />
    }
    if (data?.bannerImage) {
      return <Image height={100} w={180} objectFit={"contain"} src={ROOT_API + data.bannerImage} alt="image-hero" maxWidth="100%" />
    }
    <Text>N/A</Text>
  }

  return (
    // <Box flex flexDir="column">
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Tên quảng cáo:
        </Text>
        <Text>{detail?.campaignName}</Text>
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Thể loại:
        </Text>
        {detail?.categories?.length > 0 && (
          <Flex wordBreak="break-all" alignItems={"center"} whiteSpace={"wrap"}>
            {detail?.categories.map((item, index) => (
              <Text key={index}>{item?.name} &nbsp;</Text>
            ))}
          </Flex>
        )}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Hình ảnh:
        </Text>
        {RenderImage(detail)}
        {/* {detail?.heroImage ? <Image height={100} w={180} objectFit={"contain"} src={ROOT_API + heroImage} alt="image-hero" maxWidth="100%" /> : <Text>N/A</Text>} */}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Icon:
        </Text>
        {detail?.icon ? <Image src={ROOT_API + detail?.icon} alt="image-hero" maxWidth="100%" /> : <Text>N/A</Text>}
      </Flex>

      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Quốc gia:
        </Text>
        {!isEmpty(detail?.country) ?
          <Text>N/A</Text>
          :
          <Text>{country}</Text>
        }
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Ngân sách hằng ngày:
        </Text>
        {detail?.dailyBudget ? <Text>{detail?.dailyBudget}</Text> : <Text>N/A</Text>}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Thành phố:
        </Text>
        {detail?.cities?.length > 0 ? (
          <Flex alignItems={"center"}>
            {detail?.cities.map((item, index) => (
              <Text key={index}>{item}, </Text>
            ))}
          </Flex>
        ) :
          <Text>N/A</Text>
        }
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Tổng ngân sách:
        </Text>
        <Text>{detail?.totalBudget}</Text>
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Số lượt chuyển đổi:
        </Text>
        {detail?.CPC ? <Text>{detail?.CPC}</Text> : <Text>N/A</Text>}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Số lượt hiển thị:
        </Text>
        {detail?.CPM ? <Text>{detail?.CPM}</Text> : <Text>N/A</Text>}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Số lượt click:
        </Text>
        {<Text>{detail?.countClick}</Text>}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Tiêu đề:
        </Text>
        {detail?.title ? <Text>{detail?.title}</Text> : <Text>N/A</Text>}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Tin nhắn:
        </Text>
        {detail?.message ? <Text>{detail?.message}</Text> : <Text>N/A</Text>}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Đường dẫn mô tả:
        </Text>
        <Text wordBreak="break-all">{detail?.destinationURL}</Text>
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Hệ điều hành:
        </Text>
        <Text whiteSpace={"wrap"} wordBreak="break-all">{detail?.osName}</Text>
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Thiết bị:
        </Text>
        {detail?.devices?.length > 0 && (
          <Flex wordBreak="break-all" alignItems={"center"} whiteSpace={"wrap"}>
            {detail?.devices.map((item, index) => (
              <Text key={index}>{item} &nbsp;</Text>
            ))}
          </Flex>
        )}
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Trạng thái:
        </Text>
        <Text>{detail?.status}</Text>
      </Flex>
      <Flex gap={6} mt={4}>
        <Text fontWeight={600} minWidth={150}>
          Ngày tạo:
        </Text>
        <Text>{formatDate(detail?.createdAt)}</Text>
      </Flex>
    </Grid>
  );
};

export default DetailCampaign;
