import React, { useState } from 'react';
import { Button, Center, Flex, FormControl, FormLabel, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import { Select } from 'chakra-react-select';
import { GROUP_BY_WEBSITE_ADS, GroupByOptions, GroupByWebSiteName } from 'constants/common';
import { useQueryGetStatisticsWebsite } from 'services/statistics';
import DatePicker from 'components/DatePicker/DatePicker';
import { convertDate, convertDateEndDay, formatDate } from 'utils/helpers';
import isEmpty from 'lodash/isEmpty';
import StatisticsTable from './components/Table';
import { mappingOptionSelect } from 'utils/mapping';
import { useQueryGetCountry } from 'services/statistics';

const initFiler = {
  filterDay: new Date(formatDate(new Date())),
};

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

function Statistics() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({ ...initialFilter });
  const [statisticFilter, setStatisticFilter] = useState({
    country: null,
  });
  const [tab, setTab] = useState(GROUP_BY_WEBSITE_ADS.DATE);

  const { data: country } = useQueryGetCountry();
  const { data } = useQueryGetStatisticsWebsite({
    ...filter,
    ...(tab && { groupBy: tab }),
  });

  const onReset = () => {
    setFilter({
      ...initialFilter,
    });
    setStatisticFilter({
      country: null,
    });
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      ...statisticFilter,
      ...(!!statisticFilter?.startDate && { startDate: convertDate(statisticFilter?.startDate) }),
      ...(!!statisticFilter?.endDate && { endDate: convertDateEndDay(statisticFilter?.endDate) }),
      country: statisticFilter?.country?.value,
    });
  };

  const onChangeDate = type => date => {
    setStatisticFilter(prev => ({
      ...prev,
      [type]: date,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="20px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              {/* <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Thống kê
                </Text>
              </Flex> */}
              <Flex alignItems={'end'} flexWrap={"wrap"} gap={'20px'}>
                <FormControl width={{ base: 'full', sm: '300px' }}>
                  <FormLabel m="0">Quốc gia</FormLabel>
                  <Select
                    isClearable
                    menuShouldBlockScroll
                    value={statisticFilter?.country || null}
                    onChange={e => {
                      setStatisticFilter(prev => ({
                        ...prev,
                        country: e,
                      }));
                    }}
                    options={mappingOptionSelect(country?.data, 'name', 'code')}
                  ></Select>
                </FormControl>
                <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }}>
                  <FormLabel m="0">Ngày bắt đầu</FormLabel>
                  <DatePicker selectedDate={statisticFilter.startDate} onChange={date => onChangeDate('startDate')(date)} />
                </FormControl>
                <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }}>
                  <FormLabel m="0">Ngày kết thúc</FormLabel>
                  <DatePicker selectedDate={statisticFilter.endDate} onChange={date => onChangeDate('endDate')(date)} />
                </FormControl>
                <Button variant="second" border={'1px solid #4492E1'} color={'blue.500'} maxH="30px" alignSelf={'end'} onClick={onReset}>
                  Đặt lại
                </Button>
                <Button variant="primary" maxH="30px" px={8} fontSize={14} alignSelf={'end'} onClick={onFilter}>
                  Lọc
                </Button>
                {/* <Flex alignItems={'center'} justifyContent={'space-between'} mt={4} flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
                    <Text>Nhóm theo:</Text>
                    <Flex
                      fontSize={13}
                      color="#747474"
                      fontWeight={500}
                      bg={'white'}
                      border={'1px solid #ccc'}
                      rounded={6}
                      overflow={'hidden'}
                      mt={4}
                    >
                      {GroupByOptions.map((item, index) => (
                        <Center
                          key={index}
                          px={4}
                          py={2}
                          bg={item.value === tab ? 'blue.100' : 'white'}
                          color={item.value === tab ? 'blue.500' : '#747474'}
                          cursor={'pointer'}
                          onClick={() => {
                            setTab(item.value);
                            onReset();
                          }}
                        >
                          <Text>{item.label}</Text>
                        </Center>
                      ))}
                    </Flex>
                  </Flex> */}
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack overflow={'auto'} mt={4}>
            <StatisticsTable data={data?.data || []} title={GroupByWebSiteName[tab]} />
          </Stack>
          {!isEmpty(data?.data) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={data?.pagination?.page}
                pageLength={data?.pagination?.pageSize}
                totalRecords={data?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Statistics;
