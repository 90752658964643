import React, { useState } from 'react';
import { Flex, Image, Stack, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useUserState } from 'context/UserContext';
import { formatDate } from 'utils/helpers';
import Pagination from 'components/Pagination/Pagination';
import HistoryRechangeTable from './HistoryRechangeTable';
import { useQueryGetMyHistoryTransactions } from 'services/transaction';

function HistoryRechange() {
  const { userInfo } = useUserState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data,
    //refetch,
  } = useQueryGetMyHistoryTransactions({
    ...filter,
  });
  const { data: transactions, pagination } = data || {};

  return (
    <>
      <Flex bg={'#E2E2E2'} rounded={'20px'} padding={'20px'} gap={'20px'}>
        <Image rounded={'20px'} src="gibbresh.png" fallbackSrc="https://via.placeholder.com/150" />
        <Flex direction={'column'} gap={'16px'}>
          <Flex color={'#464646'} fontSize={'18px'}>
            Ngày tham gia:&nbsp;
            <Text display={'inline'} fontWeight={'600'}>
              {formatDate(userInfo.createdAt)}
            </Text>
          </Flex>
          <Flex color={'#464646'} fontSize={'18px'}>
            Mã giới thiệu:&nbsp;
            <Text display={'inline'} fontWeight={'600'}>
              {userInfo.referralCode}
            </Text>
          </Flex>
          <Flex color={'#464646'} fontSize={'18px'}>
            Số dư ví:&nbsp;
            <Text display={'inline'} fontWeight={'600'}>
              {userInfo.balance}₫
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Stack mt={10} justifyContent={'center'} gap={5} pt={10} w={'100%'}>
        <Stack w={'100%'} overflow={'auto'}>
          <HistoryRechangeTable transactions={transactions || []} />
        </Stack>
        {!isEmpty(transactions) && (
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={pagination?.page}
              pageLength={pagination?.pageSize}
              totalRecords={pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        )}
      </Stack>
    </>
  );
}

export default HistoryRechange;
