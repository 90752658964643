import LogoACB from 'assets/img/method-payment/ACB.png'
import LogoSAC from 'assets/img/method-payment/SAC.png'
import LogoBIDV from 'assets/img/method-payment/BIDV.png'
import LogoMSB from 'assets/img/method-payment/MSB.png'
import LogoVCB from 'assets/img/method-payment/VCB.png'
import LogoTCB from 'assets/img/method-payment/TCB.png'
import LogoMB from 'assets/img/method-payment/MB.png'
import LogoVP from 'assets/img/method-payment/VP.png'

export const BankStatus = {
    Available: '1',
    InMaintenance: '2',
};

export const PaymentGateway = {
    MomoQrCode: 'momo_qr',
    ZaloQrCode: 'zalo_qr',
    LoadCard: 'rcgcard_pc',
    OnlineBanking: 'upacp_pc',
    ViettelpayQr: 'viettel_qr',
    BankTransfer: 'bank_transfer',
    BankTransferQr: 'bank_qr',
    LoadCardZing: 'rcgcard_zing',
    VipQr: 'vip_qr',
    Bank: 'bank',
    UsdtQr: 'usdt_qr',
};

export const BankLogo = {
    ACB: LogoACB,
    SAC: LogoSAC,
    BIDV: LogoBIDV,
    MSB: LogoMSB,
    VCB: LogoVCB,
    TCB: LogoTCB,
    MB: LogoMB,
    VP: LogoVP,
  };

export const MethodsText = {
    momo_qr: 'Momo',
    zalo_qr: 'Zalo',
    rcgcard_pc: 'Thẻ cào',
    upacp_pc: 'Internet Banking',
    viettel_qr: 'Viettel Pay',
    bank_transfer: 'Chuyển nhanh 24/7',
    bank_qr: 'Quét mã ngân hàng',
    rcgcard_zing: 'Thẻ cào Zing',
    vip_qr: 'VIP QR',
    usdt_qr: 'USDT',
    bank: 'Ngân hàng',
};

export const BankMethod = [PaymentGateway.OnlineBanking, PaymentGateway.Bank, PaymentGateway.BankTransferQr, PaymentGateway.BankTransfer];
