import { Box, Button, Divider, Flex, Grid, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import InputController from 'components/Form/InputController';
import CheckboxController from 'components/Form/CheckboxController';
import { CampaignGroupValidate } from 'utils/validation';
import RadioController from 'components/Form/RadioController';
import { TurnOffKey, TurnOffOptions } from 'constants/common';
import NumericInputController from 'components/Form/NumericInputController';
import { useCreateCampaignGroupMutation, useGetCampaignGroupIdDetailQuery, useUpdateCampaignGroupMutation } from 'services/campaign';
import { toast } from 'components/Toast';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import ChooseCampaignModal from './components/ChooseCampaignModal';

const CreateCampaignGroup = () => {
  const params = useParams();
  const history = useHistory();
  const { id } = params || {};

  const { isOpen: isChooseCampaignOpen, onOpen: onChooseCampaignOpen, onClose: onChooseCampaignClose } = useDisclosure();

  const { data: campaignGroupDetail } = useGetCampaignGroupIdDetailQuery(id, {
    enabled: !!id,
  });
  const createCampaignGroupMutation = useCreateCampaignGroupMutation();
  const updateCampaignGroupMutation = useUpdateCampaignGroupMutation();

  const { control, watch, handleSubmit, trigger, reset, setValue } = useForm({
    resolver: yupResolver(CampaignGroupValidate),
    defaultValues: {
      groupName: '',
      budget: false,
      minimumCTR: TurnOffKey.Off,
      maxConversionCost: TurnOffKey.Off,
      minCTR: '',
      minDelivered: '',
      maxCost: '',
      minConversions: '',
    },
  });
  const watchBudget = watch('budget');
  const watchCTR = watch('minimumCTR');
  const watchConversion = watch('maxConversionCost');

  useEffect(() => {
    if (!isEmpty(campaignGroupDetail?.data)) {
      const { data } = campaignGroupDetail;

      reset({
        ...campaignGroupDetail?.data,
        groupName: data?.campaignGroup?.groupName,
        budget: data?.campaignGroup?.budget,
        maxConversionCost: data?.maxConversionCost ? TurnOffKey.On : TurnOffKey.Off,
        minimumCTR: data?.minimumCTR ? TurnOffKey.On : TurnOffKey.Off,
      });
    }
  }, [campaignGroupDetail?.data]);

  const onSubmit = values => {
    const dataSubmit = {
      ...values,
      minimumCTR: values?.minimumCTR === TurnOffKey.On ? true : false,
      maxConversionCost: values?.maxConversionCost === TurnOffKey.On ? true : false,
      ...(!!id && { groupID: id }),
    };

    if (!!id) {
      updateCampaignGroupMutation.mutate(dataSubmit, {
        onSuccess: () => {
          toast.showMessageSuccess('Cập nhập nhóm chiến dịch thành công');
          history.push('/admin/campaign-groups');
        },
      });

      return;
    }

    createCampaignGroupMutation.mutate(dataSubmit, {
      onSuccess: () => {
        toast.showMessageSuccess('Tạo nhóm chiến dịch thành công');
        history.push('/admin/campaign-groups');
      },
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bgColor="#e9ecf3">
          <CardBody pb={4}>
            <form>
              <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
                <Text fontSize="16px" fontWeight="700">
                  THÔNG TIN NHÓM CHIẾN DỊCH
                </Text>
                <Divider my={15} />
                <Box>
                  <InputController
                    control={control}
                    name="groupName"
                    label="Tên nhóm"
                    isRequired
                    styleContainer={{ pb: '4' }}
                    styleLabel={{ fontWeight: '700' }}
                  />
                  <Box display="flex" alignItems="center" pb={4}>
                    <Text fontWeight="700" minW={90}>
                      Ngân sách
                    </Text>
                    <CheckboxController
                      control={control}
                      name="budget"
                      content="Không giới hạn"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue('dailyBudget', '');
                        }
                        trigger();
                      }}
                    />
                  </Box>
                  <NumericInputController control={control} name="dailyBudget" label="Ngân sách tối đa 1 ngày" disabled={watchBudget} />
                </Box>
              </Box>
              <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
                <Text fontSize="16px" fontWeight="700">
                  CÀI ĐẶT TỰ ĐỘNG DỪNG CHIẾN DỊCH
                </Text>
                <Divider my={15} />
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem colSpan={{ base: 2, lg: 1 }}>
                    <Box mb="15px">
                      <Text fontWeight={700}>CTR Tối thiểu</Text>
                      <Text>Các chiến dịch trong nhóm có CTR nhỏ hơn mức này sẽ không được phân phối</Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, lg: 1 }}>
                    <Box>
                      <RadioController control={control} name="minimumCTR" options={TurnOffOptions} />
                      {watchCTR === TurnOffKey.On && (
                        <>
                          <InputController control={control} name="minCTR" label="CTR Tối thiểu" styleContainer={{ py: '4' }} />
                          <InputController control={control} name="minDelivered" isRequired label="Phân phối tối thiểu" />
                        </>
                      )}
                    </Box>
                  </GridItem>
                </Grid>

                <Divider my={25} />

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem colSpan={{ base: 2, lg: 1 }}>
                    <Box mb="15px">
                      <Text fontWeight={700}>Chi phí tối đa cho 1 conversion</Text>
                      <Text>Các chiến dịch trong nhóm có chi phí conversion cao hơn mức này sẽ bị dừng phân phối</Text>
                    </Box>
                    <Box mb="15px">
                      <Text>Cần sử dụng Pixel Code để theo dõi được giá conversion. Tìm hiểu thêm</Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, lg: 1 }}>
                    <Box>
                      <RadioController control={control} name="maxConversionCost" options={TurnOffOptions} styleContainer={{ pb: '4' }} />
                      {watchConversion === TurnOffKey.On && (
                        <>
                          <InputController
                            control={control}
                            name="maxCost"
                            label="Chi phí tối đa cho 1 conversion"
                            styleContainer={{ pb: '4' }}
                          />
                          <InputController control={control} name="minConversions" label="Số conversions tối thiểu" />
                        </>
                      )}
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
              <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
                <Text fontSize="16px" fontWeight="700">
                  THÊM CHIẾN DỊCH VÀO NHÓM
                </Text>
                <Divider my={15} />
                <Box>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      // history.push('/admin/campaign-groups/banner/create');
                      onChooseCampaignOpen();
                    }}
                  >
                    Tạo chiến dịch
                  </Button>
                </Box>
              </Box>
            </form>
            <Flex pt={4}>
              <Button colorScheme="blue" onClick={handleSubmit(onSubmit)}>
                {`${!!id ? 'Cập nhập' : 'Tạo'} nhóm chiến dịch`}
              </Button>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <ChooseCampaignModal
        isOpen={isChooseCampaignOpen}
        onClose={() => {
          onChooseCampaignClose();
        }}
      />
    </>
  );
};

export default CreateCampaignGroup;
