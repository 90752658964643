import { Box, Flex, IconButton, Table, Tbody, Td, Tfoot, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import sum from 'lodash/sum';
import map from 'lodash/map';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { getCampaignDetailUrl } from 'utils/helpers';
import { useDeleteCampaignMutation } from 'services/campaign';
import { toast } from 'components/Toast';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import ContentDetailCampaign from './Content';
import { isEmpty } from 'lodash';

const defaultData = [];

const ListCampaignGroupTable = ({ campaignGroup, campaigns, refetchData }) => {
  const history = useHistory();
  const [currentItemId, setCurrentItemId] = useState(null);
  const [sorting, setSorting] = useState([]);
  const [campaignDetail, setCampaignDetail] = useState();
  const columnHelper = createColumnHelper();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure();
  const deleteCampaignMutation = useDeleteCampaignMutation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => info.getValue(),
        footer: info => 'Total',
      }),
      columnHelper.accessor('_id', {
        header: 'id',
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor('campaignName', {
        id: 'campaign',
        header: 'Tên quảng cáo',
        cell: info => info.getValue(),
        footer: info => info.column.id,
      }),
      columnHelper.accessor('type', {
        header: 'Định dạng',
        cell: info => info.renderValue(),
        footer: <span>{0}</span>,
      }),
      // columnHelper.accessor('campaignGroup', {
      //   header: () => 'Nhóm chiến dịch',
      //   cell: info => campaignGroup?.groupName,
      //   footer: <span>{0}</span>,
      // }),
      columnHelper.accessor('views', {
        header: 'Hiển thị',
        cell: info => info.renderValue() || '0',
        footer: <span>{sum(map(campaigns, d => d.views))}</span>,
      }),
      columnHelper.accessor('clicks', {
        header: 'Clicks',
        cell: info => info.renderValue() || '0',
        footer: <span>{sum(map(campaigns, d => d.clicks))}</span>,
      }),
      columnHelper.accessor('ctr', {
        header: 'CTR (%)',
        cell: info => info.renderValue() || '0',
        footer: <span>{sum(map(defaultData, d => d.ctr))}</span>,
      }),
      columnHelper.accessor('currentBid', {
        header: 'Bid hiện tại ($)',
        cell: info => info.getValue() || '0',
        footer: <span>--</span>,
      }),
      columnHelper.accessor('averageBid', {
        header: 'Bid trung bình ($)',
        cell: info => info.renderValue() || 0,
        footer: <span>{sum(map(defaultData, d => d.cost))}</span>,
      }),
      columnHelper.accessor('budget', {
        header: 'Chi phí',
        cell: info => (info.getValue() ? 'Không giới hạn' : info?.row?.original?.dailyBudget || '--'),
        footer: <span>{sum(map(defaultData, d => d.cost))}</span>,
      }),
      columnHelper.accessor('conversions', {
        header: 'Chuyển đổi',
        cell: info => info.renderValue() || 0,
        footer: <span>{sum(map(defaultData, d => d.cost))}</span>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex gap={3}>
            <IconButton
              onClick={() => {
                setCampaignDetail(info?.row?.original);
                onOpenDetail();
              }}
            >
              <EditIcon cursor="pointer" boxSize={4} />
            </IconButton>
            <IconButton
              onClick={() => {
                setCurrentItemId(info?.row?.original?._id);
                onConfirmOpen();
              }}
            >
              <DeleteIcon cursor="pointer" color="red.500" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [campaigns]
  );

  const table = useReactTable({
    data: campaigns || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const onDeleteCampaign = () => {
    if (currentItemId) {
      deleteCampaignMutation.mutate(
        { campaignID: currentItemId },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xóa quảng cáo thành công.');
            onConfirmClose();
            setCurrentItemId(null);
            refetchData?.();
          },
          onError: () => {
            onConfirmClose();
            setCurrentItemId(null);
          },
        }
      );
    }
  };
  return (
    <>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px">
                  {header.isPlaceholder ? null : (
                    <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={12}>
                Không có dữ liệu
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
        {/* <Tfoot>
        {table.getFooterGroups().map(footerGroup => (
          <Tr key={footerGroup.id}>
            {footerGroup.headers.map(header => (
              <Fragment key={header.id}>
                {['campaign', 'groupName'].includes(header.column.id) ? null : (
                  <Th colSpan={header.column.id === '_id' ? 3 : 1}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </Th>
                )}
              </Fragment>
            ))}
          </Tr>
        ))}
      </Tfoot> */}
      </Table>
      <ConfirmDialog
        title="Thông tin chiến dịch"
        content={<ContentDetailCampaign {...campaignDetail} />}
        confirmBtn=""
        isOpen={isOpenDetail}
        onClose={onCloseDetail}
      // onConfirm={onDeleteCampaignGroup}
      />
      <ConfirmDialog
        title="Xóa quảng cáo"
        content="Bạn có chắc chắn muốn xóa quảng cáo này không?"
        confirmBtn="Xóa"
        isOpen={isConfirmOpen}
        isDeleting={deleteCampaignMutation.isPending}
        onClose={onConfirmClose}
        onConfirm={onDeleteCampaign}
      />
    </>
  );
};

export default ListCampaignGroupTable;
