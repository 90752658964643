import { Box, Button } from '@chakra-ui/react';
import React from 'react';

function VTlist({ index, data, activeTabId, onClick }) {
  const handleClickButton = () => {
    onClick(data.infor.tab);
  };

  return (
    <li key={index} style={{ listStyle: 'none', textAlign: 'left' }}>
      <Button
        display="flex"
        justifyItems="center"
        gap={'10px'}
        onClick={handleClickButton}
        bg={activeTabId === data.infor.tab ? 'gray.100' : 'transparent'}
        style={activeTabId === data.infor.tab ? { color: '#3182ce' } : { color: '#8892b0' }}
        wordBreak="break-word"
        whiteSpace="break-spaces"
      >
        <Box minW="14px" minH="14px">
          {data.infor.iconMenu}
        </Box>
        {data.infor.name}
      </Button>
    </li>
  );
}

export default VTlist;
