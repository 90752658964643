import { Box, Flex, Image, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import { BankStatus } from 'constants/bank';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { mappingBank } from 'utils/mapping';

const BankList = ({ bankList, bankSelected, onSelectBank }) => {
  const [bankData, setBankData] = useState();

  useEffect(() => {
    const newBankData = mappingBank(bankList);

    setBankData(newBankData);
  }, [bankList]);
  return (
    <Flex justifyContent={'center'} gap={5} borderTop={'1px solid #ccc'} mt={10} pt={10}>
      {!isEmpty(bankData) &&
        bankData?.map((bankItem, index) => (
          <Flex
            key={index}
            cursor={'pointer'}
            className={classNames(
              'relative col-span-1 flex justify-center items-center rounded py-[11px] px-[15px] text-center text-[#3D3D3D] border border-[#DADDE5] cursor-pointer bg-[#EAE5EE]',
              {
                '!border-[#2354E6] !bg-white': bankSelected?.bankCode === bankItem?.bankCode,
              }
            )}
            onClick={() => {
              if(bankItem.status===BankStatus.Available){
              onSelectBank?.(bankItem);
              }
            }}
          >
            {/* <div className="w-[20px] h-[20px]">
              <Image 
              bg={"#ccc"}
               border={bankSelected?.bankCode === bankItem?.bankCode ? "2px solid #3182CE" : null} rounded={"10px"} src={bankItem?.logo} alt={bankItem?.bankCode} 
              />
              <div>Bảo trì</div>
            </div> */}
            <Box position="relative" >
              <Image
                bg={bankItem.status===BankStatus.Available ? "rgba(204, 204, 204, 0.3)" :"rgba(0, 0, 0, 0.6)"}
                border={bankSelected?.bankCode === bankItem?.bankCode ? '2px solid #3182CE' : null}
                borderRadius="10px"
                src={bankItem?.logo}
                alt={bankItem?.bankCode}
              />
              {bankItem.status===BankStatus.InMaintenance &&   
              <Text position="absolute" w={'100%'} top="50%" left="50%" transform="translate(-50%, -50%)" pointerEvents="none" textAlign={'center'} textColor={'#fff'} fontWeight={'700'}>
                Bảo trì
              </Text>
}
            </Box>
          </Flex>
        ))}
    </Flex>
  );
};

export default BankList;
