import { useMemo, useState } from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';
import CheckboxController from 'components/Form/CheckboxController';
import InputController from 'components/Form/InputController';
import NumericInputController from 'components/Form/NumericInputController';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';

const AdsInfo = ({ control, categories, campaignGroupOptions, watch, setValue }) => {
  const categoryOptions = useMemo(() => mappingOptionSelect(categories || [], 'name', 'slug'));
  const [checkedAllCategory, setCheckedAllCategory] = useState(false);
  const watchBudget = watch('budget');
  const handleCheckAllCategory = e => {
    setCheckedAllCategory(!checkedAllCategory);
    if(e.target.checked){
      setValue('categories', categoryOptions)
    }else{
      setValue('categories', [])
    }
  };
  return (
    <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
      <Text fontSize="16px" fontWeight="700">
        THÔNG TIN QUẢNG CÁO
      </Text>
      <Divider my={15} />
      <Box pb={4}>
        <InputController
          control={control}
          name="campaignName"
          label="Tên quảng cáo"
          isRequired
          styleContainer={{ pb: '4' }}
          styleLabel={{ fontWeight: '700' }}
        />
        <SelectController
          control={control}
          name="campaignGroupID"
          label="Nhóm chiến dịch"
          isRequired
          styleContainer={{ pb: '4' }}
          styleLabel={{ fontWeight: '700' }}
          options={campaignGroupOptions}
          styleInput={{
            paddingTop: '0px',
          }}
        />
        <Box display="flex" alignItems="center" pb={4}>
          <Text fontWeight="700" whiteSpace="nowrap">
            Danh mục website
          </Text>
          <Text as="span" color="#e53e3e" ml="3.5px" mr={5}>
            *
          </Text>
          <CheckboxController control={control} name="allCategories" content="Tất cả danh mục" onChange={handleCheckAllCategory} />
        </Box>
        <SelectController
          isReadOnly={checkedAllCategory}
          control={control}
          isMulti
          isRequired
          name="categories"
          styleContainer={{ pb: '4' }}
          styleInput={{
            paddingTop: '0px',
          }}
          options={categoryOptions}
        />
        <Box display="flex" alignItems="center" pb={4}>
          <Text fontWeight="700" minW={90}>
            Ngân sách
            <Text as="span" color="#e53e3e" ml="3.5px">
              *
            </Text>
          </Text>
          <CheckboxController
            control={control}
            name="budget"
            content="Không giới hạn"
            // onChange={() => {
            //   trigger?.();
            // }}
          />
        </Box>
        <NumericInputController control={control} name="dailyBudget" label="Ngân sách tối đa 1 ngày" disabled={watchBudget} />
      </Box>
      {/* <Text fontWeight="700" pb={4}>
        Chú ý: Có thể gây quá ngân sách.&nbsp;
        <Text as="i" color="#337ab7" cursor="pointer" _hover={{ textDecoration: 'underline' }}>
          Tìm hiểu thêm
        </Text>
      </Text>
      <Box bgColor="#e0ebf9" borderColor="#e0ebf9" color="#327ad5" p={15} borderRadius={4}>
        <Text>
          Bạn có thể đặt ngân sách giảm 10% so với ngân sách mong muốn để hạn chế việc late clicks. Ví dụ: Bạn muốn ngân sách là $10, hãy
          đặt ngân sách là $9.
        </Text>
      </Box> */}
    </Box>
  );
};

export default AdsInfo;
