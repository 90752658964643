import React from 'react';
import { AiFillNotification } from 'react-icons/ai';
import { FiPlusCircle } from 'react-icons/fi';
import { FaTable, FaChartLine, FaUserPlus, FaUser, FaFolder } from 'react-icons/fa';
import { CiBoxList } from "react-icons/ci";

import { HomeIcon, PersonIcon, SettingsIcon } from 'components/Icons/Icons';
import Dashboard from 'views/Dashboard/Dashboard.js';
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import CampaignGroup from 'views/Campaign/CampaignGroup';
import CreateCampaignGroup from 'views/Campaign/CreateCampaignGroup';
import PushAds from 'views/Campaign/PushAds';
import NativeAds from 'views/Campaign/NativeAds';
import BannerAds from 'views/Campaign/BannerAds';
import Statistic from 'views/Campaign/Statistic';
import Ticket from 'views/Ticket/Ticket';
import ReferralDetail from 'views/Referral/Detail';
import Statistics from 'views/Statistics/Statistics';
import DirectLink from 'views/Campaign/DirectLink';
import Interstitial from 'views/Campaign/Interstitial';
import PopUnder from 'views/Campaign/PopUnder';
import ListCampaign from 'views/Campaign/CampaignGroup/components/ListCampaign';
import Campaigns from 'views/Campaign/List';
import { RiLuggageDepositFill } from 'react-icons/ri';
import Deposit from 'views/Deposit/Deposit ';

// Example:
// const routers = [
//   {
//     path: '/dashboard',
//     name: 'Dashboard',
//     icon: <HomeIcon color="inherit" />,
//     layout: '/admin',
//     component: Dashboard,
//   },
//   {
//     name: 'Sub route',
//     icon: <HomeIcon color="inherit" />,
//     layout: '/admin',
//     collapse: true,
//     views: [
//       {
//         path: '/route',
//         name: 'Pricing',
//         miniName: 'P',
//         layout: '/admin',
//         component: Dashboard,
//       },
//     ],
//   },
//   {
//     name: 'ACCOUNT PAGES',
//     category: 'account',
//     rtlName: 'صفحات',
//     state: 'pageCollapse',
//     views: [
//       {
//         path: '/sign-in',
//         layout: '/auth',
//         redirect: true,
//         component: SignIn,
//       },
//     ],
//   },
// ];

var dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: <HomeIcon color="inherit" />,
  //   layout: '/admin',
  //   component: Dashboard,
  // },
  {
    path: '/campaign-groups/banner/create',
    name: 'Banner Ads',
    icon: <FiPlusCircle color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: BannerAds,
  },
  {
    path: '/campaign-groups/:id/list',
    name: 'Nhóm chiến dịch',
    icon: <FaFolder color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: ListCampaign,
  },
  {
    path: '/campaign-groups/detail/:id',
    name: 'Nhóm chiến dịch',
    icon: <FaFolder color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: CreateCampaignGroup,
  },
  {
    path: '/campaign-groups/create',
    name: 'Tạo nhóm chiến dịch',
    icon: <FaFolder color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: CreateCampaignGroup,
  },
  {
    path: '/campaign-groups',
    name: 'Nhóm chiến dịch',
    icon: <FaFolder color="inherit" />,
    layout: '/admin',
    component: CampaignGroup,
  },
  {
    name: 'Chiến dịch',
    icon: <AiFillNotification color="inherit" />,
    layout: '/admin',
    collapse: true,
    views: [
      {
        path: '/campaign/list',
        name: 'Danh sách',
        icon: <CiBoxList color="inherit" />,
        layout: '/admin',
        component: Campaigns,
      },
      {
        path: '/campaign/interstitial/detail/:id',
        name: 'Interstitial',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        redirect: true,
        component: Interstitial,
      },
      {
        path: '/campaign/interstitial/create',
        name: 'Interstitial',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        component: Interstitial,
      },
      {
        path: '/campaign/banner/detail/:id',
        name: 'Banner Ads',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        redirect: true,
        component: BannerAds,
      },
      {
        path: '/campaign/banner/create',
        name: 'Banner Ads',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        component: BannerAds,
      },
      {
        path: '/campaign/native/detail/:id',
        name: 'Native Ads',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        redirect: true,
        component: NativeAds,
      },
      {
        path: '/campaign/native/create',
        name: 'Native Ads',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        component: NativeAds,
      },
      {
        path: '/campaign/direct-link/detail/:id',
        name: 'Direct link',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        redirect: true,
        component: DirectLink,
      },
      {
        path: '/campaign/direct-link/create',
        name: 'Direct link',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        component: DirectLink,
      },
      {
        path: '/campaign/pop-under/detail/:id',
        name: 'Pop under',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        redirect: true,
        component: PopUnder,
      },
      {
        path: '/campaign/pop-under/create',
        name: 'Pop under',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        component: PopUnder,
      },
      {
        path: '/campaign/detail/:id',
        name: 'Push Ads',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        redirect: true,
        component: PushAds,
      },
      {
        path: '/campaign/create',
        name: 'Push Ads',
        icon: <FiPlusCircle color="inherit" />,
        layout: '/admin',
        component: PushAds,
      },
      // {
      //   path: '/campaign-groups/detail/:id',
      //   name: 'Nhóm chiến dịch',
      //   icon: <FaFolder color="inherit" />,
      //   layout: '/admin',
      //   redirect: true,
      //   component: CreateCampaignGroup,
      // },
      // {
      //   path: '/campaign-groups/create',
      //   name: 'Tạo nhóm chiến dịch',
      //   icon: <FaFolder color="inherit" />,
      //   layout: '/admin',
      //   redirect: true,
      //   component: CreateCampaignGroup,
      // },
      // {
      //   path: '/campaigns',
      //   name: 'Thống kê',
      //   icon: <FaChartLine color="inherit" />,
      //   layout: '/admin',
      //   component: Statistic,
      // },
      // {
      //   path: '/campaign-groups/:id/list',
      //   name: 'Nhóm chiến dịch',
      //   icon: <FaFolder color="inherit" />,
      //   layout: '/admin',
      //   redirect: true,
      //   component: ListCampaign,
      // },
    ],
  },
  {
    name: 'Link giới thiệu',
    icon: <FaUserPlus color="inherit" />,
    layout: '/admin',
    path: '/referral',
    component: ReferralDetail,
    // collapse: true,
    // views: [
    //   {
    //     path: '/referral',
    //     name: 'Chi tiết tài khoản',
    //     icon: <FaUser color="inherit" />,
    //     layout: '/admin',
    //     component: ReferralDetail,
    //   },
    // ],
  },
  {
    path: '/statistics-website',
    name: 'Thống kê',
    icon: <FaTable />,
    layout: '/admin',
    component: Statistics,
  },
  {
    path: '/ticket',
    name: 'Ticket',
    icon: <SettingsIcon color="inherit" />,
    layout: '/admin',
    component: Ticket,
  },
  {
    path: '/deposit',
    name: 'Quản lý giao dịch',
    icon: <RiLuggageDepositFill color="inherit" />,
    layout: '/admin',
    component: Deposit,
  },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        component: Profile,
      },
      {
        path: '/sign-in',
        layout: '/auth',
        redirect: true,
        component: SignIn,
      },
      {
        path: '/sign-up',
        layout: '/auth',
        redirect: true,
        component: SignUp,
      },
    ],
  },
];

export default dashRoutes;
