import { AdsType, Devices } from 'constants/campaign';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import moment from 'moment-timezone';

export const isJsonString = str => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export function rangeDesc(start, end) {
  return Array(start - end + 1)
    .fill()
    .map((_, idx) => start - idx);
}

export const today = moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');

export const formatDate = (date, format = 'MM/DD/YYYY') => moment(date).format(format);

export const getInitFilerChart = () => ({
  startDate: new Date(formatDate(moment(new Date()).subtract(6, 'days'))),
  endDate: new Date(formatDate(new Date())),
});

export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getCampaignDevices = ({ isAll, isDesktop, isSmartphone, isTablet }) => {
  if (isAll) return [Devices.Desktop, Devices.Smartphone, Devices.Tablet];

  return [isDesktop ? Devices.Desktop : '', isSmartphone ? Devices.Smartphone : '', isTablet ? Devices.Tablet : ''].filter(item => !!item);
};

export const getCampaignDetailUrl = type => {
  switch (type) {
    case AdsType.PushAds:
      return '/campaign/detail';
    case AdsType.NativeAds:
      return '/campaign/native/detail';
    case AdsType.BannerAds:
      return '/campaign/banner/detail';
    case AdsType.DirectLink:
      return '/campaign/direct-link/detail';
    case AdsType.Interstitial:
      return '/campaign/interstitial/detail';
    case AdsType.PopUnder:
      return '/campaign/pop-under/detail';
    default:
      return '/campaign/detail';
  }
};

export const convertToTitleCase = (str) => {
  const words = str.split('_');
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const convertedStr = capitalizedWords.join(' ');
  return convertedStr;
};

export const convertDate = dateString => {
  const date = new Date(moment(dateString).add(1, 'days'));;
  date.setHours(0, 0, 0, 0);
  return date.toISOString().split("T")[0] + "T00:00:01.000Z";
};

export const convertDateEndDay = dateString => {
  const date = new Date(dateString);
  date.setHours(23, 59, 59, 999);
  return date;
};

export function currencyFormat(x) {
  if (!x) {
    return '0 VND';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' VND';
}