import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import ReferralDetailTable from './components/Table';
import { useQueryUserProfile } from 'services/user';
import { ADVERTISER_PAGE_URL } from 'constants/common';
import useCopyToClipboard from 'hooks/use-copy-to-clipboard';
import { toast } from 'components/Toast';
import { useGetReferralOfUserQuery } from 'services/referral';

const ReferralDetail = () => {
  const history = useHistory();
  const [, copy] = useCopyToClipboard();
  const { data: userProfile } = useQueryUserProfile();
  const { data } = useGetReferralOfUserQuery();
  const { data: referralUser, pagination } = data || {};

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      {/* <Flex pt="22px">
        <Flex direction="column" alignItems="flex-end">
          <Flex>
            <Button
              variant="primary"
              maxH="30px"
              m="10px"
              onClick={() => {
                history.push('/admin/campaign/banner/create');
              }}
            >
              Tạo chiến dịch
            </Button>
          </Flex>
        </Flex>
      </Flex> */}

      {userProfile?.data?.referralCode && (
        <Card bgColor="white" mb="22px">
          <CardBody>
            <Stack>
              <Text fontWeight="700" textColor="#333">
                Link giới thiệu
              </Text>
              <Flex gap={3}>
                <Text textColor="blue.400">{`${ADVERTISER_PAGE_URL}/#/auth/sign-up?ref=${userProfile?.data?.referralCode}`}</Text>
                <CopyIcon
                  boxSize={6}
                  cursor="pointer"
                  onClick={() => {
                    copy(`${ADVERTISER_PAGE_URL}/#/auth/sign-up?ref=${userProfile?.data?.referralCode}`);
                    toast.showMessageSuccess('Copy thành công.');
                  }}
                />
              </Flex>
            </Stack>
          </CardBody>
        </Card>
      )}

      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px" bgColor="white">
        <CardBody>
          <Stack overflow={'auto'}>
            <ReferralDetailTable referralUser={referralUser || []} />
          </Stack>
          {/* {!isEmpty(campaignGroups) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={pagination?.page}
                pageLength={pagination?.pageSize}
                totalRecords={pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter(prev => ({
                    ...prev,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  }));
                }}
              />
            </Flex>
          )} */}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default ReferralDetail;
