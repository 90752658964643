import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { DirectLinkValidate } from 'utils/validation';
import { InitFilter } from 'constants/common';
import { toast } from 'components/Toast';
import {
  createPushAdsCampaign,
  updatePushAdsCampaign,
  useGetCampaignDetailQuery,
  useGetCampaignGroupQuery,
  useGetCategoriesQuery,
  useGetCountriesQuery,
  useGetOSNameQuery,
} from 'services/campaign';
import AdsInfo from 'components/CampaignDetails/AdsInfo';
import BidAndTarget from 'components/CampaignDetails/BidAndTarget';
import { AdsType, Devices } from 'constants/campaign';
import { getCampaignDevices } from 'utils/helpers';
import { mappingCountries, mappingOSNameOptions, mappingOptionSelect } from 'utils/mapping';
import InputController from 'components/Form/InputController';

const DataOmit = ['heroImage', 'createdAt', 'updatedAt', '__v', '__id', 'devices', 'type', '_id', 'campaignGroup'];

const PopUnder = () => {
  const bidAndTargetRef = useRef();
  const params = useParams();
  const history = useHistory();
  const { id } = params || {};
  const [scheduleHour, setScheduleHour] = useState([]);
  const { data: categories } = useGetCategoriesQuery();
  const { data: countries } = useGetCountriesQuery();
  const { data: campaignGroups } = useGetCampaignGroupQuery();
  const { data: osName } = useGetOSNameQuery();
  const { data: campaignDetail } = useGetCampaignDetailQuery(id, {
    enabled: !!id,
  });
  const countryOptions = useMemo(() => mappingCountries(countries?.data || []), [countries?.data]);
  const campaignGroupOptions = useMemo(() => mappingOptionSelect(campaignGroups?.data, 'groupName', '_id'), [campaignGroups?.data]);
  const osNameOptions = useMemo(() => mappingOSNameOptions(osName?.data || {}), [osName?.data]);

  const { control, watch, handleSubmit, trigger, setValue, reset } = useForm({
    resolver: yupResolver(DirectLinkValidate),
    defaultValues: {
      campaignName: '',
      campaignGroupID: undefined,
      budget: false,
      destinationURL: '',
      countryCode: undefined,
      osName: undefined,
      version: undefined,
    },
  });

  useEffect(() => {
    if (!isEmpty(campaignDetail?.data)) {
      const { data } = campaignDetail || {};

      reset({
        ...omit(data, DataOmit),
        categories: mappingOptionSelect(data?.categories || [], 'name', 'slug'),
        cities: data?.cities.map(item => ({ label: item, value: item })),
        countryCode: countryOptions?.find(item => item?.value === data?.countryCode),
        campaignGroupID: campaignGroupOptions?.find(item => item?.value === data?.campaignGroup?._id),
        osName: osNameOptions?.find(item => item?.value === data?.osName),
      });

      handleInitDevicesValue(data?.devices);

      data?.version && bidAndTargetRef.current?.getVersionOptions(data?.osName, data?.version);
      !isEmpty(data?.cities) && bidAndTargetRef.current?.getCityOptions(data?.countryCode, data?.cities);
    }
  }, [campaignDetail?.data, countryOptions, campaignGroupOptions, osNameOptions, bidAndTargetRef]);

  const handleInitDevicesValue = devices => {
    if (devices?.length === 3) {
      setValue('allDevices', true);
      setValue('isDesktop', true);
      setValue('isSmartphone', true);
      setValue('isTablet', true);
    } else {
      setValue('isDesktop', devices.includes(Devices.Desktop));
      setValue('isSmartphone', devices.includes(Devices.Smartphone));
      setValue('isTablet', devices.includes(Devices.Tablet));
    }
  };

  const onSubmit = async dataForm => {
    try {
      const devices = getCampaignDevices({
        isAll: dataForm?.allDevices,
        isDesktop: dataForm?.isDesktop,
        isSmartphone: dataForm?.isSmartphone,
        isTablet: dataForm?.isTablet,
      });

      const formData = new FormData();

      devices.forEach(item => formData.append('devices[]', item));
      formData.append('type', AdsType.PopUnder);
      for (let key in dataForm) {
        if (!isUndefined(dataForm[key]) && !isNull(dataForm[key])) {
          isArray(dataForm[key])
            ? dataForm[key].forEach(item => formData.append(key + '[]', isObject(item) ? item?.value : item))
            : isObject(dataForm[key])
            ? formData.append(key, dataForm[key]?.value)
            : formData.append(key, dataForm[key]);
        }
      }
      formData.append('country', formData?.countryCode?.name);
      !!id && formData.append('campaignID', id);

      const response = await (id ? updatePushAdsCampaign(formData) : createPushAdsCampaign(formData));

      if (response?.code === 0) {
        toast.showMessageSuccess(`${!!id ? 'Câp nhập' : 'Tạo'} chiến dịch Direct Link thành công.`);
        // history.push('/admin/campaigns');
        history.push(`/admin/campaign-groups/${dataForm.campaignGroupID?.value}/list`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px" bgColor="#e9ecf3">
        <CardBody pb={4} position="relative">
          <Flex gap="24px">
            <Box width={{ base: '100%' }}>
              <form>
                <AdsInfo
                  control={control}
                  categories={categories?.data}
                  campaignGroupOptions={campaignGroupOptions}
                  watch={watch}
                  trigger={trigger}
                />
                <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
                  <Text fontSize="16px" fontWeight="700">
                    NỘI DUNG QUẢNG CÁO
                  </Text>
                  <Divider my={15} />

                  <Box>
                    <InputController
                      control={control}
                      name="destinationURL"
                      label="Liên kết đích"
                      isRequired
                      styleLabel={{ fontWeight: '700' }}
                      styleContainer={{ pb: '4' }}
                    />
                  </Box>
                </Box>
                <BidAndTarget
                  ref={bidAndTargetRef}
                  control={control}
                  type={AdsType.PopUnder}
                  countryOptions={countryOptions}
                  osNameOptions={osNameOptions}
                  setValue={setValue}
                />
                {/* <OtherSetting control={control} scheduleHour={scheduleHour} setScheduleHour={setScheduleHour} /> */}
              </form>
              <Flex pt={4}>
                <Button colorScheme="blue" onClick={handleSubmit(onSubmit)}>
                  {`${!!id ? 'Câp nhập' : 'Tạo'} chiến dịch`}
                </Button>
              </Flex>
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default PopUnder;
