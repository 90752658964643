import React, { useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import capitalize from 'lodash/capitalize';
import { useHistory } from 'react-router-dom';
import { AdsType, CampaignLink } from 'constants/campaign';

export default function ChooseCampaignModal({ isOpen, onClose }) {
  const cancelRef = useRef(null);
  const history = useHistory();

  const onCampaignSelect = e => {
    history.push(CampaignLink[e?.value]);
  };

  return (
    <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
      <AlertDialogOverlay />

      <AlertDialogContent minWidth={500}>
        <AlertDialogHeader textTransform="uppercase">Chọn loại chiến dịch</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Select
            options={Object.keys(AdsType)?.map(key => ({ label: capitalize(AdsType[key]?.replaceAll('_', ' ')), value: AdsType[key] }))}
            placeholder="Chọn"
            onChange={e => {
              onCampaignSelect(e);
            }}
          />
        </AlertDialogBody>
        <AlertDialogFooter></AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
