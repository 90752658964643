import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input as InputComponent, Text, Textarea } from '@chakra-ui/react';

const InputCustom = ({
    type = 'text',
    hidden = false,
    readOnly = false,
    name,
    label,
    control,
    styleContainer,
    styleBoxInput,
    styleLabel,
    classNameRequired,
    isRequired,
    disabled,
    extendsComponent,
    placeholder,
    requiredIndicator,
    onChange,
}) => (
    <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
            <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}
            >
                <Box {...styleBoxInput}>
                    <Flex flexDirection="column">
                        {type === 'textarea' ? (
                            <Textarea
                                {...field}
                                className="form-control"
                                type={type}
                                disabled={disabled}
                                placeholder={placeholder}
                                onChange={e => {
                                    onChange?.(e);
                                    field.onChange(e);
                                }}
                            />
                        ) : (
                            <Box position='relative'>
                                <InputComponent
                                    {...field}
                                    className="form-control"
                                    readOnly={readOnly}
                                    position="relative"
                                    borderRadius="12px"
                                    paddingTop={12}
                                    paddingBottom={6}
                                    marginTop={7}
                                    paddingX='16px'
                                    outline='none'
                                    focusBorderColor='#1DCA74'
                                    hidden={hidden}
                                    type={type}
                                    disabled={disabled}
                                    placeholder={placeholder}
                                    _placeholder={{ opacity: 1, color: 'gray.300' }}
                                    onChange={e => {
                                        onChange?.(e);
                                        field.onChange(e);
                                    }}
                                />
                                {label && (
                                    <FormLabel position="absolute" fontSize='14px' transform='translateY(-50%)' top="50%" left="16px" requiredIndicator={requiredIndicator} {...styleLabel} minW="150px" color={error ? '#FF0000' : '#666666'}>
                                    <Text className={classNameRequired}>{label}</Text>
                                    </FormLabel>
                                )}
                            </Box>
                        )}
                        <FormErrorMessage>{error && error?.message}</FormErrorMessage>
                    </Flex>
                    {extendsComponent && <Box>{extendsComponent}</Box>}
                </Box>
            </FormControl>
        )}
    />
);

export default InputCustom;
