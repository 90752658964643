import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getReferralOfUser = () => request.get(ROOT_API + '/api/v1/advertiser/list-referral');

// Mutation

// Query
export const useGetReferralOfUserQuery = (options = {}) =>
  useQuery({ queryKey: ['getReferralOfUser'], queryFn: () => getReferralOfUser(), ...options });
