import { Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { FaPlus } from 'react-icons/fa6';
import FileSelect from 'components/Form/FileSelectController';
import InputController from 'components/Form/InputController';
import { BannerSizeOptions, TrackingParams } from 'constants/campaign';
import SelectController from 'components/Form/SelectController';

const AdsContent = forwardRef(({ control, fileSelected, error, handleFileSelect, handleErrorFile, watch }, ref) => {
  const watchBannerSize = watch('bannerSize');

  return (
    <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
      <Text fontSize="16px" fontWeight="700">
        NỘI DUNG QUẢNG CÁO
      </Text>
      <Divider my={15} />

      <Box>
        <InputController
          control={control}
          name="destinationURL"
          label="Liên kết đích"
          isRequired
          styleLabel={{ fontWeight: '700' }}
          styleContainer={{ pb: '4' }}
        />
        {/* <Flex pb={4} gap={4}>
          <Box minW="120px">Tracking params:</Box>
          <Flex flexWrap="wrap" flex="1" gap="5px">
            {TrackingParams.map((item, index) => (
              <Flex
                key={index}
                alignItems="center"
                bgColor="#e1e5ec"
                borderColor="#e1e5ec"
                color="#666"
                padding="5px 10px"
                borderRadius="3px"
                fontSize="12px"
                cursor="pointer"
              >
                <FaPlus />
                <Text ml="4px" as="span">
                  {item.label}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <Box pb={4}>
          <Text as="i" color="#337ab7" cursor="pointer" _hover={{ textDecoration: 'underline' }}>
            Tìm hiểu thêm về Tracking Params
          </Text>
        </Box> */}
        <SelectController
          control={control}
          name="bannerSize"
          label="Kích thước banner"
          styleContainer={{ pb: '4' }}
          styleLabel={{ fontWeight: '700' }}
          styleInput={{
            paddingTop: "0px"
          }}
          options={BannerSizeOptions}
          isRequired
        />
        {watchBannerSize && (
          <Box>
            <Box>
              <Flex mb="7px">
                <Text fontWeight="700">
                  Ảnh banner
                  <Text as="span" color="#e53e3e" ml="3.5px">
                    *
                  </Text>
                </Text>
                <Text as="i" ml={1}>{`(${watchBannerSize?.value}, tối đa 1MB)`}</Text>
              </Flex>
              <Box>
                <FileSelect
                  name="bannerImage"
                  styleButton={{ borderTopLeftRadius: '0 !important', borderBottomLeftRadius: '0' }}
                  leftAddon={
                    <Box
                      px="14px"
                      flex={1}
                      display="flex"
                      alignItems="center"
                      h="30px"
                      bgColor="#eef1f5"
                      borderTopLeftRadius="5px"
                      borderBottomLeftRadius="5px"
                    >
                      {fileSelected?.bannerImage?.file?.name}
                    </Box>
                  }
                  handleFileSelect={handleFileSelect}
                  handleErrorFile={handleErrorFile}
                />
                <Text color="#737373" fontSize="12px">
                  Hỗ trợ gif, png, jpeg, jpg
                </Text>
              </Box>
            </Box>
            <Box>
              {!!error.bannerImage && (
                <Text pt={1} color={'red.500'} fontSize="13px">
                  {error.bannerImage}
                </Text>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default AdsContent;
