export const TrackingParams = [
  {
    label: 'Campaign ID',
    value: 'campaign_id',
  },
  {
    label: 'Site ID',
    value: 'site_id',
  },
  {
    label: 'User ID',
    value: 'user_id',
  },
  {
    label: 'Country Code',
    value: 'country_code',
  },
  {
    label: 'Ad Format ID',
    value: 'ad_format_id',
  },
  {
    label: 'Click ID',
    value: 'click_id',
  },
  {
    label: 'Hour',
    value: 'hour',
  },
  {
    label: 'Date',
    value: 'date',
  },
];

export const AdsType = {
  PushAds: 'PUSH_ADS',
  NativeAds: 'NATIVE_ADS',
  BannerAds: 'BANNER_ADS',
  DirectLink: 'DIRECT_LINK',
  Interstitial: 'INTERSTITIAL',
  PopUnder: 'POP_UNDER',
};

export const Devices = {
  Desktop: 'DESKTOP',
  Smartphone: 'SMARTPHONE',
  Tablet: 'TABLET',
};

export const ScheduleHourData = Array.from({ length: 24 }, (_, index) => index).map(item => ({ value: `${item}h` }));

export const BannerSize = {
  '300x250': '300x250',
  '300x600': '300x600',
  '320x50': '320x50',
  '970x90': '970x90',
  '640x320': '640x320',
  '160x600': '160x600',
  '320x100': '320x100',
};

export const BannerSizeOptions = [
  {
    label: '300x250 - Desktop, Smartphone, Tablet',
    value: BannerSize['300x250'],
  },
  {
    label: '300x600 - Desktop',
    value: BannerSize['300x600'],
  },
  {
    label: '320x50 - Smartphone, Tablet',
    value: BannerSize['320x50'],
  },
  {
    label: '970x90 - Desktop',
    value: BannerSize['970x90'],
  },
  {
    label: '640x320 - Desktop',
    value: BannerSize['640x320'],
  },
  {
    label: '160x600 - Desktop',
    value: BannerSize['160x600'],
  },
  {
    label: '320x100 - Smartphone, Tablet',
    value: BannerSize['320x100'],
  },
];

export const OSNameKey = {
  All: 'ALL',
  Android: 'ANDROID',
  Ios: 'IOS',
  Mac: 'MAC',
  Windows: 'WINDOWS',
};

export const OSFullName = {
  [OSNameKey.All]: '-- Tất cả hệ điều hành --',
  [OSNameKey.Android]: 'Android',
  [OSNameKey.Ios]: 'iOS',
  [OSNameKey.Mac]: 'Mac OS',
  [OSNameKey.Windows]: 'Windows',
};

export const ContactChannelOptions = [
  {
    label: 'Telegram',
    value: 'telegram',
  },
  {
    label: 'Facebook',
    value: 'facebook',
  },
];

export const CampaignLink = {
  [AdsType.PushAds]: '/admin/campaign/create',
  [AdsType.NativeAds]: '/admin/campaign/native/create',
  [AdsType.BannerAds]: '/admin/campaign/banner/create',
  [AdsType.DirectLink]: '/admin/campaign/direct-link/create',
  [AdsType.Interstitial]: '/admin/campaign/interstitial/create',
  [AdsType.PopUnder]: '/admin/campaign/pop-under/create',
};
