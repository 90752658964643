import { useEffect, useRef, useState } from 'react';
import { Button, Flex, Input, InputGroup, InputRightElement, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { SearchIcon } from '@chakra-ui/icons';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CampaignGroupTable from './components/Table';
import { useGetCampaignGroupQuery } from 'services/campaign';
import { InitFilter } from 'constants/common';
import Pagination from 'components/Pagination/Pagination';
import { CookieStorage } from 'utils/cookie-storage';

const CampaignGroup = () => {
  const refSearchButton = useRef(null);
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState({ ...InitFilter });
  const [searchValue, setSearchValue] = useState('');

  const { data, refetch } = useGetCampaignGroupQuery(filter);
  const { data: campaignGroups, pagination } = data || {};
  const isLoggedIn = CookieStorage.isAuthenticated();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} minH="400px" pb="0px" bgColor="white">
        <CardHeader p="6px 0px 22px 0px">
          {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
            NHÓM CHIẾN DỊCH
          </Text> */}
          <Flex>
            <Flex direction="column" alignItems="flex-end">
              <Flex>
                <Button
                  variant="primary"
                  maxH="30px"
                  // m="10px"
                  onClick={() => {
                    history.push('/admin/campaign-groups/create');
                  }}
                >
                  Tạo nhóm chiến dịch
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex columnGap={3} mb={3}>
            <InputGroup minWidth={"250px"} maxW={"300px"}>
              <InputRightElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputRightElement>
              <Input
                type="text"
                variant="outline"
                placeholder="Tìm kiếm theo tên nhóm"
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                onKeyUp={event => {
                  if (event.key === 'Enter' && event?.target?.value) {
                    const searchBtn = refSearchButton?.current;
                    if (searchBtn !== null) {
                      searchBtn.click();
                    }
                  }
                }}
              />
            </InputGroup>

            <Button
              ref={refSearchButton}
              disabled={!searchValue}
              colorScheme="blue"
              onClick={() => {
                setFilter({
                  ...filter,
                  searchKeyword: searchValue,
                  pageIndex: 0,
                });
              }}
            >
              Lọc
            </Button>
            <Button
              onClick={() => {
                setSearchValue('');
                setFilter({
                  ...filter,
                  searchKeyword: '',
                  pageIndex: 0,
                });
              }}
            >
              Đặt lại
            </Button>
          </Flex>
          <Stack overflow={'auto'}>
            <CampaignGroupTable campaignGroups={campaignGroups || []} refetchData={refetch} />
          </Stack>
          {!isEmpty(campaignGroups) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={pagination?.page}
                pageLength={pagination?.pageSize}
                totalRecords={pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter(prev => ({
                    ...prev,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  }));
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CampaignGroup;
