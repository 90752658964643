import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';

const ConfirmDialog = ({ title, content, confirmBtn, isOpen, isCentered, isDeleting, onClose, onConfirm, width = "calc(2/3 * 100vw)" }) => {
  const cancelRef = useRef();

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered={isCentered}>
      <AlertDialogOverlay >
        <AlertDialogContent width={width} maxW={'none'}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{content}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Hủy
            </Button>
            {confirmBtn && (
              <Button
                colorScheme="red"
                ml={3}
                isLoading={isDeleting}
                onClick={() => {
                  onConfirm?.();
                }}
              >
                {confirmBtn}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmDialog;
