import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { ROOT_API } from 'constants/common';
import { isEmpty } from 'lodash';
import React from 'react';
import { formatDate } from 'utils/helpers';

const ContentDetailCampaign = ({
    campaignName,
    heroImage,
    country,
    dailyBudget,
    CPC,
    CPM,
    title,
    message,
    icon,
    osName,
    totalBudget,
    countClick,
    status,
    destinationURL,
    devices,
    cities,
    createdAt,
    categories
}) => {
    return (
        // <Box flex flexDir="column">
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Tên quảng cáo:
                </Text>
                <Text>{campaignName}</Text>
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Thể loại:
                </Text>
                {categories.length > 0 && (
                    <Flex wordBreak="break-all" alignItems={"center"} whiteSpace={"wrap"}>
                        {categories.map((item, index) => (
                            <Text key={index}>{item?.name} &nbsp;</Text>
                        ))}
                    </Flex>
                )}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Hình ảnh:
                </Text>
                {heroImage ? <Image height={100} w={180} objectFit={"contain"} src={ROOT_API + heroImage} alt="image-hero" maxWidth="100%" /> : <Text>N/A</Text>}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Icon:
                </Text>
                {icon ? <Image src={ROOT_API + icon} alt="image-hero" maxWidth="100%" /> : <Text>N/A</Text>}
            </Flex>

            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Quốc gia:
                </Text>
                {!isEmpty(country) ?
                    <Text>N/A</Text>
                    :
                    <Text>{country}</Text>
                }
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Ngân sách hằng ngày:
                </Text>
                {dailyBudget ? <Text>{dailyBudget}</Text> : <Text>N/A</Text>}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Thành phố:
                </Text>
                {cities.length > 0 ? (
                    <Flex alignItems={"center"}>
                        {cities.map((item, index) => (
                            <Text key={index}>{item}, </Text>
                        ))}
                    </Flex>
                ) :
                    <Text>N/A</Text>
                }
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Tổng ngân sách:
                </Text>
                <Text>{totalBudget}</Text>
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Số lượt chuyển đổi:
                </Text>
                {CPC ? <Text>{CPC}</Text> : <Text>N/A</Text>}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Số lượt hiển thị:
                </Text>
                {CPM ? <Text>{CPM}</Text> : <Text>N/A</Text>}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Số lượt click:
                </Text>
                {countClick ? <Text>{countClick}</Text> : <Text>N/A</Text>}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Tiêu đề:
                </Text>
                {title ? <Text>{title}</Text> : <Text>N/A</Text>}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Tin nhắn:
                </Text>
                {message ? <Text>{message}</Text> : <Text>N/A</Text>}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Đường dẫn mô tả:
                </Text>
                <Text wordBreak="break-all">{destinationURL}</Text>
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Hệ điều hành:
                </Text>
                <Text whiteSpace={"wrap"} wordBreak="break-all">{osName}</Text>
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Thiết bị:
                </Text>
                {devices.length > 0 && (
                    <Flex wordBreak="break-all" alignItems={"center"} whiteSpace={"wrap"}>
                        {devices.map((item, index) => (
                            <Text key={index}>{item} &nbsp;</Text>
                        ))}
                    </Flex>
                )}
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Trạng thái:
                </Text>
                <Text>{status}</Text>
            </Flex>
            <Flex gap={6} mt={4}>
                <Text fontWeight={600} minWidth={150}>
                    Ngày tạo:
                </Text>
                <Text>{formatDate(createdAt)}</Text>
            </Flex>
        </Grid>
    );
};

export default ContentDetailCampaign;
