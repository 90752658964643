import capitalize from "lodash/capitalize";

export const ROOT_API = process.env.REACT_APP_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;
export const PUBLISHER_PAGE_URL = process.env.REACT_APP_PUBLISHER_URL;
export const ADVERTISER_PAGE_URL = process.env.REACT_APP_ADVERTISER_URL;

export const MAX_PHONE_NUMBER_DIGIT = 10;
export const defaultPassword = 'CrmEmail2023@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;
export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager',
};

export const ToastStatus = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const KEY_REGEX = /_/g;
export const CONVERT_UPPERCASE_REGEX = /\b\w/g;
export const URL_REGEX = /^((https?|ftp)\:\/\/((\[?(\d{1,3}\.){3}\d{1,3}\]?)|(([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,4}))(\:\d+)?(\/[-a-zA-Z0-9._?,'+&amp;%$#=~\\]+)*\/?)$/;

export const convertEnumToLabelOption = target => {
  return target
    .replace(KEY_REGEX, ' ')
    .toLowerCase()
    .replace(CONVERT_UPPERCASE_REGEX, l => l.toUpperCase());
};

export const ContactChannelKey = {
  Facebook: 'facebook',
  Telegram: 'telegram',
  Skype: 'skype',
};

export const ContactChannelOptions = Object.keys(ContactChannelKey).map(key => ({
  label: key,
  value: ContactChannelKey[key],
}));

export const CountryKey = {
  Vietnam: 'VN',
  Thailan: 'TH',
  Other: 'other',
};

export const CountryOptions = [
  {
    label: 'Việt Nam',
    value: CountryKey.Vietnam,
  },
  {
    label: 'Thái Lan',
    value: CountryKey.Thailan,
  },
  {
    label: 'Khác',
    value: CountryKey.Other,
  },
];

export const TurnOffKey = {
  On: 'on',
  Off: 'off',
};

export const TurnOffOptions = [
  {
    label: 'Bật',
    value: TurnOffKey.On,
  },
  {
    label: 'Tắt',
    value: TurnOffKey.Off,
  },
];

export const InitFilter = {
  pageSize: 10,
  pageIndex: 0,
};

export const FileImageValid = ['png', 'jpeg', 'jpg'];

export const TYPE_ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const TYPE_TICKET = {
  GENERAL_QUESTION: 'GENERAL_QUESTION',
  MY_ACCOUNT: 'MY_ACCOUNT',
  PAYMENTS: 'PAYMENTS',
  REQUEST_MANAGER: 'REQUEST_MANAGER',
  VISA_MASTERCARD: 'VISA_MASTERCARD',
};

export const TypeTicket = Object.keys(TYPE_TICKET).map(key => ({
  label: convertEnumToLabelOption(key),
  value: TYPE_TICKET[key],
}));

export const STATUS_TICKET = {
  PENDING: "Pending",
  PROCESSING: "Processing",
  FINISHED: "finished",
};

export const StatusTicketOptions = Object.keys(STATUS_TICKET).map(key => ({
  label: capitalize(key),
  value: STATUS_TICKET[key],
}));


export const TabFeatureProfile = {
  IdentityInfo: 'Thông tin liên lạc của tôi',
  ChangePassword: 'Thay đổi mật khẩu',
  Rechange: 'Nạp tiền',
  HistoryRechange: 'Lịch sử nạp tiền'
}

export const GROUP_BY_WEBSITE_ADS = {
  DATE: 'DATE',
  PLACEMENT: 'PLACEMENT',
  COUNTRY: 'COUNTRY',
  DEVICE: 'DEVICE',
  DOMAIN: 'DOMAIN',
  SYSTEM: 'SYSTEM',
};

export const GroupByWebSiteName = {
  [GROUP_BY_WEBSITE_ADS.DATE]: 'Ngày',
  [GROUP_BY_WEBSITE_ADS.PLACEMENT]: 'Vị trí',
  [GROUP_BY_WEBSITE_ADS.COUNTRY]: 'Quốc gia',
  [GROUP_BY_WEBSITE_ADS.DEVICE]: 'Thiết bị',
  [GROUP_BY_WEBSITE_ADS.DOMAIN]: 'Domain',
  [GROUP_BY_WEBSITE_ADS.SYSTEM]: 'Hệ thống',
};

export const GroupByOptions = Object.keys(GROUP_BY_WEBSITE_ADS).map(key => ({
  label: GroupByWebSiteName[key],
  value: GROUP_BY_WEBSITE_ADS[key],
}));
