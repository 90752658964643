import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getCampaignGroup = params => request.get(ROOT_API + '/api/v1/advertiser/campaign-groups', { params });
export const getDetailCampaignGroup = groupID => request.get(ROOT_API + `/api/v1/advertiser/campaign-groups/${groupID}`);
export const createCampaignGroup = data => request.post(ROOT_API + '/api/v1/advertiser/campaign-groups', data);
export const updateCampaignGroup = data => request.post(ROOT_API + '/api/v1/advertiser/campaign-groups/update', data);
export const deleteCampaignGroup = data => request.post(ROOT_API + '/api/v1/advertiser/campaign-groups/delete', data);
export const getCategories = params => request.get(ROOT_API + '/api/v1/categories', { params });
export const getCountries = params => request.get(ROOT_API + '/api/v1/advertiser/campaign/countries', { params });
export const getCity = data => request.get(ROOT_API + `/api/v1/advertiser/campaign/cities/${data?.countryName}`);
export const createPushAdsCampaign = (data, config) =>
  request.post(ROOT_API + '/api/v1/advertiser/campaign', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const updatePushAdsCampaign = (data, config) =>
  request.post(ROOT_API + '/api/v1/advertiser/campaign/update', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const getCampaignDetail = campaignId => request.get(ROOT_API + `/api/v1/advertiser/campaign/${campaignId}`);
export const getCampaignGroupDetail = campaignGroupId => request.get(ROOT_API + `/api/v1/advertiser/campaign-groups/${campaignGroupId}`);
export const getOSNames = () => request.get(ROOT_API + `/api/v1/advertiser/campaign/os-name`);
export const getVersionByOS = data => request.get(ROOT_API + `/api/v1/advertiser/campaign/list-version/${data?.osName}`);
export const getCampaigns = params => request.get(ROOT_API + '/api/v1/advertiser/campaign', { params });
export const deleteCampaign = data => request.post(ROOT_API + '/api/v1/advertiser/campaign/delete', data);

// Mutation
export const useCreateCampaignGroupMutation = () => useMutation({ mutationFn: createCampaignGroup });
export const useUpdateCampaignGroupMutation = () => useMutation({ mutationFn: updateCampaignGroup });
export const useDeleteCampaignGroupMutation = () => useMutation({ mutationFn: deleteCampaignGroup });
export const useGetCityMutation = () => useMutation({ mutationFn: getCity });
export const useGetVersionByOSMutation = () => useMutation({ mutationFn: getVersionByOS });
export const useDeleteCampaignMutation = () => useMutation({ mutationFn: deleteCampaign });

// Query
export const useGetCampaignGroupQuery = (params, options) => {
  return useQuery({ queryKey: ['getCampaignGroup', params], queryFn: () => getCampaignGroup(params), ...options });
};
export const useGetDetailCampaignGroupQuery = (groupID, options) => {
  return useQuery({ queryKey: ['getDetailCampaignGroup', groupID], queryFn: () => getDetailCampaignGroup(groupID), ...options });
};
export const useGetCategoriesQuery = (params, options) => {
  return useQuery({ queryKey: ['getCategories', params], queryFn: () => getCategories(params), ...options });
};
export const useGetCountriesQuery = (params, options) => {
  return useQuery({ queryKey: ['getCountries', params], queryFn: () => getCountries(params), ...options });
};
export const useGetCampaignDetailQuery = (campaignId, options = {}) =>
  useQuery({
    queryKey: ['getCampaignDetail', campaignId],
    queryFn: () => getCampaignDetail(campaignId),
    ...options,
  });
export const useGetCampaignGroupIdDetailQuery = (campaignGroupId, options = {}) =>
  useQuery({
    queryKey: ['getCampaignGroupDetail', campaignGroupId],
    queryFn: () => getCampaignGroupDetail(campaignGroupId),
    ...options,
  });
export const useGetOSNameQuery = options => {
  return useQuery({ queryKey: ['getOSNames'], queryFn: () => getOSNames(), ...options });
};
export const useGetCampaignsQuery = (params, options) => {
  return useQuery({ queryKey: ['getCampaigns', params], queryFn: () => getCampaigns(params), ...options });
};
