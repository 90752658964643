import { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import CheckboxController from 'components/Form/CheckboxController';
import InputController from 'components/Form/InputController';
import NumericInputController from 'components/Form/NumericInputController';
import SelectController from 'components/Form/SelectController';
import { useGetCityMutation, useGetVersionByOSMutation } from 'services/campaign';
import { AdsType, OSNameKey } from 'constants/campaign';

const VersionDefaultOption = { label: '-- Tất cả phiên bản --', value: 'ALL' };

const BidAndTarget = forwardRef(({ control, type, countryOptions, osNameOptions, setValue }, ref) => {
  const [cityOptions, setCityOptions] = useState([]);
  const [versionOptions, setVersionOptions] = useState([VersionDefaultOption]);
  const [checkedAllDevice, setCheckedAllDevice] = useState(false);
  const getCityMutation = useGetCityMutation();
  const getVersionByOSMutation = useGetVersionByOSMutation();

  useImperativeHandle(
    ref,
    () => {
      return {
        getCityOptions: (countryName, cities) => {
          getCityMutation.mutate(
            { countryName },
            {
              onSuccess: response => {
                const options = response?.data?.map(item => ({ label: item, value: item }));

                setCityOptions(options);
              },
            }
          );
        },
        getVersionOptions: (osName, version) => {
          if (version === 'ALL') {
            setValue('version', { label: '-- Tất cả phiên bản --', value: 'ALL' });

            return;
          }

          getVersionByOSMutation.mutate(
            { osName },
            {
              onSuccess: response => {
                const options = [VersionDefaultOption, ...response?.data?.map(item => ({ label: item, value: item }))];

                setVersionOptions(options);
                setValue(
                  'version',
                  options?.find(item => Number(item?.value) === Number(version))
                );
              },
            }
          );
        },
      };
    },
    [getVersionByOSMutation, setVersionOptions, getCityMutation, setCityOptions]
  );

  const handleCheckAllDevice = e => {
    if (!e.target.checked) {
      setValue('isDesktop', false);
      setValue('isSmartphone', false);
      setValue('isTablet', false);
    }
    if (e.target.checked) {
      setValue('isDesktop', true);
      setValue('isSmartphone', true);
      setValue('isTablet', true);
    }
    setCheckedAllDevice(!checkedAllDevice);
  };

  return (
    <Box bgColor="white" borderRadius="10px" padding="12px 20px" mb={25}>
      <Text fontSize="16px" fontWeight="700">
        ĐẤU GIÁ VÀ NHẮM MỤC TIÊU
      </Text>
      <Divider my={15} />
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 2 }} w="100%">
          <SelectController
            control={control}
            name="countryCode"
            label="Quốc gia"
            styleContainer={{ pb: '4' }}
            styleLabel={{ fontWeight: '700' }}
            isRequired
            options={countryOptions}
            styleInput={{
              paddingTop: "0px"
            }}
            onChange={e => {
              setValue('cities', null);
              getCityMutation.mutate(
                { countryName: e?.name },
                {
                  onSuccess: response => {
                    setCityOptions(response?.data?.map(item => ({ label: item, value: item })));
                  },
                }
              );
            }}
          />
          <SelectController
            control={control}
            isMulti
            name="cities"
            label="Thành phố"
            styleContainer={{ pb: '4' }}
            styleLabel={{ fontWeight: '700' }}
            styleInput={{
              paddingTop: "0px"
            }}
            options={cityOptions}
          />
          <Box display="flex" alignItems="center" pb={4}>
            <Text fontWeight="700" minW="60px">
              Thiết bị
            </Text>
            <CheckboxController control={control} name="allDevices" onChange={handleCheckAllDevice} content="Tất cả thiết bị" />
          </Box>
          <Grid templateColumns="repeat(3, 1fr)" gap={4} pb={4}>
            <GridItem w="100%">
              <CheckboxController isReadOnly={checkedAllDevice} control={control} name="isDesktop" content="Desktop" />
            </GridItem>
            <GridItem w="100%">
              <CheckboxController isReadOnly={checkedAllDevice} control={control} name="isSmartphone" content="Smartphone" />
            </GridItem>
            <GridItem w="100%">
              <CheckboxController isReadOnly={checkedAllDevice} control={control} name="isTablet" content="Tablet" />
            </GridItem>
          </Grid>
          {type === AdsType.BannerAds ? (
            <Box pb={4}>
              {/* <SelectController
                control={control}
                name="osName"
                label="Hệ đều hành"
                styleContainer={{ pb: '4' }}
                styleLabel={{ fontWeight: '700' }}
                styleInput={{
                  paddingTop: "0px"
                }}
                options={osNameOptions}
                onBlur={e => {
                  console.log(e);
                }}
                onChange={e => {
                  setValue('version', VersionDefaultOption);

                  if (e?.value === OSNameKey.All) {
                    setVersionOptions([VersionDefaultOption]);
                    return;
                  }

                  getVersionByOSMutation.mutate(
                    { osName: e?.value },
                    {
                      onSuccess: response => {
                        setVersionOptions([VersionDefaultOption, ...response?.data?.map(item => ({ label: item, value: item }))]);
                      },
                    }
                  );
                }}
              />
              <SelectController
                control={control}
                name="version"
                label={`Phiên bản tối thiểu`}
                styleContainer={{ pb: '4' }}
                styleLabel={{ fontWeight: '700' }}
                styleInput={{
                  paddingTop: "0px"
                }}
                options={versionOptions}
              /> */}
              <NumericInputController control={control} name="CPM" label="CPM" isRequired styleLabel={{ fontWeight: '700' }} />
            </Box>
          ) : (
            <NumericInputController control={control} name="CPC" label="CPC" isRequired styleLabel={{ fontWeight: '700' }} />
          )}
          <Flex justifyContent="end">
            <Text as="i">CPC tối thiểu là $0.018</Text>
          </Flex>
        </GridItem>
        {/* <GridItem colSpan={{ base: 2, xl: 1 }} w="100%">
          <Flex pb={1}>
            <Text fontWeight="700">Site ID</Text>
            <Text ml={1}>(Mỗi Site ID trên một dòng)</Text>
          </Flex>
          <InputController
            type="textarea"
            control={control}
            name="includeSiteID"
            label="Include"
            placeholder="SiteID_001
SiteID_002
SiteID_003
..."
            styleContainer={{ pb: '4' }}
          />
          <InputController
            type="textarea"
            control={control}
            name="excludeSiteID"
            label="Exclude"
            placeholder="SiteID_001
SiteID_002
SiteID_003
..."
            styleContainer={{ pb: '4' }}
          />
          <Flex pb={1}>
            <Text fontWeight="700">User Blacklist</Text>
            <Text ml={1}>(Mỗi Site ID trên một dòng)</Text>
          </Flex>
          <InputController
            type="textarea"
            control={control}
            name="blackList"
            placeholder="SiteID_001
SiteID_002
SiteID_003
..."
            styleContainer={{ pb: '4' }}
          />
        </GridItem> */}
      </Grid>
    </Box>
  );
});

export default BidAndTarget;
