import { Box, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table';
import { Fragment, useMemo, useState } from 'react';
import sum from 'lodash/sum';
import map from 'lodash/map';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

const defaultData = [];

const ReferralDetailTable = ({ referralUser }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor('_id', {
        header: 'Advertiser ID',
        cell: info => info.getValue(),
        footer: info => 'Tổng',
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày đăng ký',
        cell: info => moment(info.getValue()).format('DD/MM/YYYY HH:mm:ss'),
        footer: info => info.column.id,
      }),
      columnHelper.accessor('deposit', {
        id: 'deposit',
        header: 'Tổng tiền nạp',
        cell: info => info.getValue() || 0,
        footer: <span>{sum(map(referralUser, d => d.deposit)) || 0}</span>,
      }),
      columnHelper.accessor('commission', {
        header: 'Hoa hồng',
        cell: info => info.renderValue() || 0,
        footer: <span>{sum(map(referralUser, d => d.commission)) || 0}</span>,
      }),
    ],
    [referralUser]
  );

  const table = useReactTable({
    data: referralUser || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px">
                  {header.isPlaceholder ? null : (
                    <Box
                    // cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null} */}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={4}>
                Không có dữ liệu
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
        <Tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <Fragment key={header.id}>
                  {['createdAt'].includes(header.column.id) ? null : (
                    <Th colSpan={header.column.id === '_id' ? 2 : 1}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </Th>
                  )}
                </Fragment>
              ))}
            </Tr>
          ))}
        </Tfoot>
      </Table>
    </>
  );
};

export default ReferralDetailTable;
