import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardBody, CardHeader, Divider, Flex, Grid, GridItem, Text, Wrap, useColorModeValue } from '@chakra-ui/react';
import VTlist from './VerticalList';
import VTcontent from './VerticalContent';
import { useUserDispatch, useUserState } from 'context/UserContext';
import InputController from 'components/Form/InputController';
import ChangePassword from './ChangePassword';
import { UpdateProfileFormValidate } from 'utils/validation';
import { TabFeatureProfile } from 'constants/common';
import Rechange from 'views/Dashboard/Profile/components/Rechange';
import HistoryRechange from 'views/Dashboard/Profile/components/HistoryRechange';
import { useUpdateProfileMutation } from 'services/user';
import { CookieStorage } from 'utils/cookie-storage';
import { StorageKeys } from 'constants/storage-keys';
import { toast } from 'components/Toast';

function VerticalTab(props) {
  const [activeTabId, setActiveTabId] = useState(TabFeatureProfile.IdentityInfo);

  const { userInfo } = useUserState();
  const userDispatch = useUserDispatch();
  const updateProfile = useUpdateProfileMutation();
  const textColor = useColorModeValue('white', 'white');
  const textTitleColor = useColorModeValue('gray.600', 'white');

  function handleClickButton(tab) {
    setActiveTabId(tab);
  }

  const { control, reset, handleSubmit } = useForm({
    resolver: yupResolver(UpdateProfileFormValidate),
  });

  useEffect(() => {
    reset({
      ...userInfo,
    });
  }, [userInfo]);

  const onSubmit = values => {
    updateProfile.mutate(
      {
        email: values?.email,
        phone: values?.phone,
        fullname: values?.fullname,
        username: values?.username,
      },
      {
        onSuccess: res => {
          if (res?.data) {
            CookieStorage.setCookieData(StorageKeys.UserInfo, JSON.stringify(res?.data));
            userDispatch({ type: 'UPDATE_USER_INFO', data: { user: res?.data } });
            toast.showMessageSuccess('Cập nhật thông tin thành công');
          }
        },
        onError: error => {
          console.error(error);
        },
      }
    );
  };

  return (
    <Wrap w={'100%'}>
      <Grid w={'100%'} gridTemplateColumns={{ base: '1fr', md: '20% 1fr' }} gap={6}>
        <GridItem w="100%">
          <Flex bg={'white'} borderRadius={4} padding={'20px'}>
            <Flex flexDirection={'column'} gap={'10px'}>
              {props.data.map((item, index) => (
                <VTlist key={index} onClick={handleClickButton} data={item} index={index} activeTabId={activeTabId} />
              ))}
            </Flex>
          </Flex>
        </GridItem>
        <GridItem w="100%">
          <VTcontent key={TabFeatureProfile.IdentityInfo} index={TabFeatureProfile.IdentityInfo} activeTabId={activeTabId}>
            <Card w={'100%'} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Thông tin liên lạc của tôi
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <form>
                  <InputController
                    control={control}
                    name="username"
                    label="Username"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                    disabled
                  />
                  <InputController
                    control={control}
                    name="email"
                    label="Email"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                    disabled
                  />
                  <InputController
                    control={control}
                    name="fullname"
                    label="Tên đầy đủ"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                  {/* <SelectController
                    control={control}
                    placeholder="Chọn quốc gia"
                    name="countryCode"
                    label="Quốc gia"
                    options={CountryOptions}
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    focusBorderColor="#3182ce"
                    size="md"
                  /> */}
                  <InputController
                    control={control}
                    name="phone"
                    label="Số điện thoại"
                    isRequired
                    styleContainer={{ display: 'flex', alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1 }}
                    styleLabel={{ marginTop: '10px' }}
                  />
                </form>
                <Button mt={'30px'} p="0px" bg="transparent" variant="no-effects">
                  <Flex
                    align="center"
                    w={{ sm: '100%', lg: '135px' }}
                    bg="blue.500"
                    borderRadius="8px"
                    justifyContent="center"
                    py="10px"
                    boxShadow="2px 2px 5.5px rgba(0, 0, 0, 0.06)"
                    cursor="pointer"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <Text fontSize="small" color={'white'} fontWeight="bold">
                      Cập nhật thông tin
                    </Text>
                  </Flex>
                </Button>
              </CardBody>
            </Card>
          </VTcontent>
          <VTcontent key={TabFeatureProfile.ChangePassword} index={TabFeatureProfile.ChangePassword} activeTabId={activeTabId}>
            <Card w={'100%'} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Thay đổi mật khẩu
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <ChangePassword />
              </CardBody>
            </Card>
          </VTcontent>
          <VTcontent key={TabFeatureProfile.Rechange} index={TabFeatureProfile.Rechange} activeTabId={activeTabId}>
            <Card w={'100%'} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Nạp tiền
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <Rechange />
              </CardBody>
            </Card>
          </VTcontent>
          <VTcontent key={TabFeatureProfile.HistoryRechange} index={TabFeatureProfile.HistoryRechange} activeTabId={activeTabId}>
            <Card w={'100%'} py="10px">
              <CardHeader p="6px 20px 10px 20px">
                <Flex direction="column">
                  <Text fontSize="larger" color={textTitleColor} fontWeight="bold">
                    Lịch sử nạp tiền
                  </Text>
                </Flex>
              </CardHeader>
              <Divider color={textColor} />
              <CardBody>
                <HistoryRechange />
              </CardBody>
            </Card>
          </VTcontent>
        </GridItem>
      </Grid>
    </Wrap>
  );
}

export default VerticalTab;
