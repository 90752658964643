import React from 'react';
import { Flex } from '@chakra-ui/react';

import { useUserState } from 'context/UserContext';
import VerticalTab from 'components/VerticalTab/VerticalTab';
import { ImProfile } from 'react-icons/im';
import { IoKey } from 'react-icons/io5';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { MdHistory } from 'react-icons/md';
import { TabFeatureProfile } from 'constants/common';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TabFeatureProfile.IdentityInfo,
        name: 'Thông tin liên lạc của tôi',
        iconMenu: <ImProfile width={14} height={14} />,
      },
    },
    {
      infor: {
        tab: TabFeatureProfile.ChangePassword,
        name: 'Thay đổi mật khẩu',
        iconMenu: <IoKey width={14} height={14} />,
      },
    },
    {
      infor: {
        tab: TabFeatureProfile.Rechange,
        name: 'Nạp tiền',
        iconMenu: <RiMoneyDollarBoxLine width={14} height={14} />,
      },
    },
    {
      infor: {
        tab: TabFeatureProfile.HistoryRechange,
        name: 'Lịch sử nạp tiền',
        iconMenu: <MdHistory width={14} height={14} />,
      },
    },
  ],
};

function Profile() {
  const { isAuthenticated } = useUserState();

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      {!!isAuthenticated ? (
        <>
          <VerticalTab data={sideMenu.data} />
        </>
      ) : (
        ''
      )}
    </Flex>
  );
}

export default Profile;
