import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Flex, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";
import InputController from "components/Form/InputController";
import { ChangePasswordFormValidate } from "utils/validation";
import { useChangePasswordMutation } from "services/user";
import { toast } from "components/Toast";

function ChangePassword() {
    const { colorMode } = useColorMode();
    const textColor = useColorModeValue('white', 'white');

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(ChangePasswordFormValidate),
        defaultValues: {
            password: '',
            newPassword: '',
            passwordConf: ''
        }
    })
    const changePasswordMutation = useChangePasswordMutation()
    const onSubmit = values => {
        changePasswordMutation.mutate(values, {
            onSuccess: () => {
                toast.showMessageSuccess('Thay đổi mật khẩu thành công.');
                reset();
            },
            onError: err => {
                throw new Error(err)
            }
        })
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputController
                    control={control}
                    hasIconPassword
                    type="password"
                    name="password"
                    label="Mật khẩu cũ"
                    isRequired
                    styleContainer={{ display: 'flex', flexDirection: "column", alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1, width: "100%" }}
                />
                <InputController
                    control={control}
                    hasIconPassword
                    type="password"
                    name="newPassword"
                    label="Mật khẩu mới"
                    isRequired
                    styleContainer={{ display: 'flex', flexDirection: "column", alignItems: 'start' }}
                    styleBoxInput={{ flex: 1, width: "100%" }}
                />
                <Box mb={'15px'} mt={1}>
                    <Text color="gray.500">
                        Tối thiểu 8 kí tự.
                    </Text>
                    <Text color="blue.500">
                        Chỉ bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
                    </Text>
                </Box>
                <InputController
                    control={control}
                    hasIconPassword
                    type="password"
                    name="passwordConf"
                    label="Xác nhận mật khẩu mới"
                    isRequired
                    styleContainer={{ display: 'flex', flexDirection: "column", alignItems: 'start', marginBottom: '15px' }}
                    styleBoxInput={{ flex: 1, width: "100%" }}
                />
                <Box mb={'15px'} mt={1}>
                    <Text color="gray.500">
                        Trùng với mật khẩu mới.
                    </Text>
                    <Text color="gray.500">
                        Tối thiểu 8 kí tự.
                    </Text>
                    <Text color="blue.500">
                        Chỉ bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
                    </Text>
                </Box>
            </form>
            <Button type="submit" p="0px" bg="transparent" variant="no-effects">
                <Flex
                    align="center"
                    w={{ sm: '100%', lg: '135px' }}
                    bg={colorMode === 'dark' ? 'navy.900' : 'blue.500'}
                    borderRadius="8px"
                    justifyContent="center"
                    py="10px"
                    boxShadow="2px 2px 5.5px rgba(0, 0, 0, 0.06)"
                    cursor="pointer"
                    onClick={handleSubmit(onSubmit)}
                >
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                        Thay đổi mât khẩu
                    </Text>
                </Flex>
            </Button>
        </>
    )
}

export default ChangePassword