import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getProfile = () => request.get('/api/v1/advertiser/profile');
export const register = data => request.post('/api/v1/advertiser/register', data);
export const login = data => request.post('/api/v1/advertiser/login', data);
export const updateProfile = data => request.post('/api/v1/advertiser/change-information', data);

export const changePassword = data => request.post('/api/v1/advertiser/change-password', data);

// Mutation
export const useRegisterMutation = () => useMutation({ mutationFn: register });
export const useLoginMutation = () => useMutation({ mutationFn: login });
export const useChangePasswordMutation = () => useMutation({ mutationFn: changePassword });
export const useUpdateProfileMutation = () => useMutation({ mutationFn: updateProfile });

// Query
export const useQueryUserProfile = (options = {}) => useQuery({ queryKey: ['getUserProfile'], queryFn: () => getProfile(), ...options });
