import * as yup from 'yup';

import { ErrorForm } from 'constants/error';
import { EMAIL_REGEX, PASSWORD_REGEX, TurnOffKey, URL_REGEX } from 'constants/common';

export const schemaTest = {
  isIncludedLetter: value => value?.match(/[a-z]/g),
  isIncludedCapitalLetter: value => value?.match(/[A-Z]/g),
  isIncludedNumber: value => value?.match(/[0-9]/g),
  isIncludedSymbol: value => value?.match(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/g),
  isValidPassword: value => {
    const passed = [];
    if (value.length >= 10) {
      passed.push('safeLength');
    }
    if (schemaTest.isIncludedLetter(value)) {
      passed.push('isIncludedLetter');
    }
    if (schemaTest.isIncludedCapitalLetter(value)) {
      passed.push('isIncludedCapitalLetter');
    }
    if (schemaTest.isIncludedNumber(value)) {
      passed.push('isIncludedNumber');
    }
    if (schemaTest.isIncludedSymbol(value)) {
      passed.push('isIncludedSymbol');
    }
    return {
      isValid: passed.length === 5,
      passed,
    };
  },
  isValidAlias: value => (!value ? true : value?.match(/^(?!-)([A-Za-z0-9-](?!.*--)){0,62}[A-Za-z0-9]$/g)),
  isValidEmail: value =>
    !value
      ? true
      : value?.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        ),
  isValidGoogleMapUrl: value => (!value ? true : value?.match(/^(https:\/\/)(goo\.gl|google\.com)\/maps\/([^\s\\]+)$/g)),
  isValidAliasName: value => value.match(/^(?!-)([A-Za-z0-9-](?!.*--)){0,62}[A-Za-z0-9]$/g),
  isValidDomain: value => value.match(/^(((?!-)[A-Za-z0-9-]{0,62}[A-Za-z0-9])\.)+((?!-)[A-Za-z0-9-]{1,62}[A-Za-z0-9])(\.)?$/g),
  isValidPassword: value => value.match(PASSWORD_REGEX),
};

export const inputAmountMoneyValidate = amount => {
  if (!amount) return ErrorForm.Required;
  if (amount < 20000) return ErrorForm.MinMoney(20000);
  if (amount > 150000000) return ErrorForm.MaxMoney(150000000);
};

// Validate form
export const RegisterFormValidate = yup.object().shape({
  username: yup
    .string()
    .min(5, ErrorForm.MaximumUsernameLength)
    .max(30, ErrorForm.MaximumUsernameLength)
    .nullable()
    .required(ErrorForm.Required),
  email: yup
    .string()
    .max(255, ErrorForm.MaxLength(255))
    .email(ErrorForm.EmailInvalid)
    .matches(EMAIL_REGEX, {
      message: ErrorForm.EmailInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  password: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  passwordConf: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .oneOf([yup.ref('password'), null], ErrorForm.PasswordNotSame)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  // contactChannel: yup.object().nullable().required(ErrorForm.Required),
  // contactUsername: yup.string().nullable().required(ErrorForm.Required),
  // company: yup.string().nullable().required(ErrorForm.Required),
  // countryCode: yup.object().nullable().required(ErrorForm.Required),
  phone: yup
    .string()
    .max(10, ErrorForm.MaximumPhoneLength)
    .matches(/(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/, {
      message: ErrorForm.PhoneInvalid,
    })
    .nullable()
    .required(ErrorForm.Required),
});

export const LoginFormValidate = yup.object().shape({
  username: yup.string().nullable().required(ErrorForm.Required),
  password: yup.string().nullable().required(ErrorForm.Required),
});

export const CampaignGroupValidate = yup.object().shape({
  groupName: yup.string().nullable().required(ErrorForm.Required),
  dailyBudget: yup.string().when('budget', {
    is: false,
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
  minDelivered: yup.string().when('minimumCTR', {
    is: minimumCTR => (minimumCTR === TurnOffKey.On ? true : false),
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
});

export const PushAdsValidate = yup.object().shape({
  campaignGroupID: yup.object().nullable().required(ErrorForm.Required),
  categories: yup.array(yup.object()).min(1, "Cần có ít nhất một lựa chọn").nullable().required(ErrorForm.Required),
  campaignName: yup.string().nullable().required(ErrorForm.Required),
  dailyBudget: yup.string().when('budget', {
    is: false,
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
  title: yup.string().nullable().required(ErrorForm.Required).max(35, ErrorForm.MaxLength(35)),
  message: yup.string().nullable().required(ErrorForm.Required).max(50, ErrorForm.MaxLength(50)),
  destinationURL: yup.string().nullable().required(ErrorForm.Required).matches(URL_REGEX, {
    message: ErrorForm.UrlInValid,
    excludeEmptyString: true,
  }),
  countryCode: yup.object().nullable().required(ErrorForm.Required),
  CPC: yup.string().nullable().required(ErrorForm.Required).test('CPC_greater_than_0.018','', function(value) {
    if(parseFloat(value) < parseFloat(0.018)) {
      return this.createError({ message: 'Giá trị CPC phải lớn hơn hoặc bằng 0.018' })
    }
    return true
  }),
});

export const NativeAdsValidate = yup.object().shape({
  campaignName: yup.string().nullable().required(ErrorForm.Required),
  campaignGroupID: yup.object().nullable().required(ErrorForm.Required),
  categories: yup.array(yup.object()).min(1, "Cần có ít nhất một lựa chọn").nullable().required(ErrorForm.Required),
  dailyBudget: yup.string().when('budget', {
    is: false,
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
  title: yup.string().nullable().required(ErrorForm.Required).max(35, ErrorForm.MaxLength(35)),
  destinationURL: yup.string().nullable().required(ErrorForm.Required).matches(URL_REGEX, {
    message: ErrorForm.UrlInValid,
    excludeEmptyString: true,
  }),
  countryCode: yup.object().nullable().required(ErrorForm.Required),
  CPC: yup.string().nullable().required(ErrorForm.Required),
});

export const BannerAdsValidate = yup.object().shape({
  campaignName: yup.string().nullable().required(ErrorForm.Required),
  campaignGroupID: yup.object().nullable().required(ErrorForm.Required),
  categories: yup.array(yup.object()).min(1, "Cần có ít nhất một lựa chọn").nullable().required(ErrorForm.Required),
  dailyBudget: yup.string().when('budget', {
    is: false,
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
  destinationURL: yup.string().nullable().required(ErrorForm.Required).matches(URL_REGEX, {
    message: ErrorForm.UrlInValid,
    excludeEmptyString: true,
  }),
  bannerSize: yup.object().nullable().required(ErrorForm.Required),
  countryCode: yup.object().nullable().required(ErrorForm.Required),
  CPM: yup.string().nullable().required(ErrorForm.Required),
});

export const TicketFormValidate = yup.object().shape({
  subject: yup.string().required(ErrorForm.Required),
  content: yup.string().required(ErrorForm.Required),
  type: yup.object().nullable().required(ErrorForm.Required),
});

export const ChangePasswordFormValidate = yup.object().shape({
  password: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  newPassword: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  passwordConf: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .oneOf([yup.ref('newPassword'), null], ErrorForm.PasswordNotSame)
    .matches(PASSWORD_REGEX, {
      message: ErrorForm.PasswordInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
});

export const UpdateProfileFormValidate = yup.object().shape({
  fullname: yup
    .string()
    .min(5, ErrorForm.MaximumStringLength('Tên đầy đủ phải'))
    .max(30, ErrorForm.MaximumStringLength('Tên đầy đủ phải'))
    .nullable()
    .required(ErrorForm.Required),
  // messenger: yup.object().shape({
  //   label: yup.string().required(ErrorForm.Required),
  //   value: yup.string().required(ErrorForm.Required),
  // }),
  // contactUsername: yup.string().nullable().required(ErrorForm.Required),
  email: yup
    .string()
    .max(255, ErrorForm.MaxLength(255))
    .email(ErrorForm.EmailInvalid)
    .matches(EMAIL_REGEX, {
      message: ErrorForm.EmailInvalid,
      excludeEmptyString: true,
    })
    .nullable()
    .required(ErrorForm.Required),
  phone: yup
    .string()
    .max(10, ErrorForm.MaximumPhoneLength)
    .matches(/(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/, {
      message: ErrorForm.PhoneInvalid,
    })
    .nullable()
    .required(ErrorForm.Required),
});

export const DirectLinkValidate = yup.object().shape({
  campaignName: yup.string().nullable().required(ErrorForm.Required),
  campaignGroupID: yup.object().nullable().required(ErrorForm.Required),
  categories: yup.array(yup.object()).min(1, "Cần có ít nhất một lựa chọn").nullable().required(ErrorForm.Required),
  dailyBudget: yup.string().when('budget', {
    is: false,
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
  destinationURL: yup.string().nullable().required(ErrorForm.Required).matches(URL_REGEX, {
    message: ErrorForm.UrlInValid,
    excludeEmptyString: true,
  }),
  countryCode: yup.object().nullable().required(ErrorForm.Required),
  CPC: yup.string().nullable().required(ErrorForm.Required),
});

export const InterstitialAdsValidate = yup.object().shape({
  campaignName: yup.string().nullable().required(ErrorForm.Required),
  dailyBudget: yup.string().when('budget', {
    is: false,
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
  title: yup.string().nullable().required(ErrorForm.Required).max(35, ErrorForm.MaxLength(35)),
  message: yup.string().nullable().required(ErrorForm.Required).max(50, ErrorForm.MaxLength(50)),
  destinationURL: yup.string().nullable().required(ErrorForm.Required).matches(URL_REGEX, {
    message: ErrorForm.UrlInValid,
    excludeEmptyString: true,
  }),
  countryCode: yup.object().nullable().required(ErrorForm.Required),
  CPC: yup.string().nullable().required(ErrorForm.Required),
});
