import { SearchIcon } from '@chakra-ui/icons';
import { Button, Flex, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useRef, useState } from 'react';

const Headers = ({ handleFilter, handleReset }) => {
  const refSearchButton = useRef(null);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Flex columnGap={3} mb={3}>
      <InputGroup maxW={"300px"}>
        <InputRightElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputRightElement>
        <Input
          type="text"
          variant="outline"
          placeholder="Tên quảng cáo"
          value={searchValue}
          onChange={event => setSearchValue(event.target.value)}
          onKeyUp={event => {
            if (event.key === 'Enter' && event?.target?.value) {
              const searchBtn = refSearchButton?.current;
              if (searchBtn !== null) {
                searchBtn.click();
              }
            }
          }}
        />
      </InputGroup>
      <Button
        ref={refSearchButton}
        disabled={!searchValue}
        colorScheme="blue"
        onClick={() => handleFilter(searchValue)}
      >
        Lọc
      </Button>
      <Button
        onClick={() => {
          handleReset()
          setSearchValue('');
        }}
      >
        Đặt lại
      </Button>
    </Flex>
  )
};

export default Headers;