import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { STATUS_TICKET } from 'constants/common';
import { useCreateTicketMutation, useUpdateTicketMutation } from 'services/ticket';
import { TicketFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';

const TicketForm = ({ isOpen, typeTicket = [], editTicketDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createTicket = useCreateTicketMutation();
  const editTicket = useUpdateTicketMutation();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(TicketFormValidate),
    defaultValues: {
      subject: editTicketDetail?.subject || '',
      content: editTicketDetail?.content || '',
      type: typeTicket.find(item => item.value === editTicketDetail?.type?._id) || undefined,
      id: editTicketDetail?._id || '',
    },
  });

  const onSubmit = values => {
    if (isEmpty(editTicketDetail)) {
      createTicket.mutate(
        { ...values, type: values?.type?.value },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Đã tạo thành công.');
            refetch();
            onClose();
          },
        }
      );
    } else {
      const payload = {
        id: values.id,
        data: { ...values, type: values?.type?.value },
      };
      editTicket.mutate(payload, {
        onSuccess: () => {
          toast.showMessageSuccess('Đã tạo thành công.');
          refetch();
          onClose();
        },
      });
    }
  };

  return (
    <>
      <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent minWidth={500}>
          <AlertDialogHeader textTransform="uppercase">
            {isEmpty(editTicketDetail) ? 'Tạo thông tin Ticket' : 'Thông tin Ticket'}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <SelectController
                styleInput={{
                  padding: '0 !important',
                }}
                control={control}
                name="type"
                label="Loại"
                placeholder="Chọn"
                isRequired
                options={typeTicket}
              />
              <InputController styleContainer={{ pt: '4' }} control={control} name="subject" label="Tiêu đề" isRequired />
              <InputController
                type="textarea"
                styleContainer={{ pt: '4' }}
                control={control}
                name="content"
                label="Nội dung"
                rows={6}
                isRequired
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isDisabled={!isEmpty(editTicketDetail) ? editTicketDetail?.status !== STATUS_TICKET.PENDING : false}
              onClick={handleSubmit(onSubmit)}
            >
              {!isEmpty(editTicketDetail) ? 'Cập nhật' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default TicketForm;
